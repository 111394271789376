import { createSlice } from '@reduxjs/toolkit'
import localData from '../../helpers/localData'

export const pageSlice = createSlice({
	name: 'page',
	
	initialState: {
		value: localData().getPage(),
	},

	reducers: {
		//#################################################################################
		setPage: (state, action) => {
			console.log('-- setPage :: action', action)
			localData().setPage( action.payload )
			state.value = action.payload
		},

		//#################################################################################
		// clearCustomer: (state) => {
		// 	state.value = null
		// 	localData().removeCustomer()
		// },
	},
})

// Action creators are generated for each case reducer function
export const { setPage /*, clearCustomer*/ } = pageSlice.actions

export default pageSlice.reducer