import * as React from 'react'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from "classnames"

import './UserArticleResultList.scss'

// UI components
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
//
// import TextTruncate from 'react-text-truncate' ... Zeigt &quot; als Text und nicht als Zeichen
import TextTruncate from '../TextTruncate/TextTruncate'

// tools
import dateFormat from "../../helpers/dateFormat"
import routes from "../../routes/routes"
import StrapiImage from "../../helpers/StrapiImage"
import getArticleDescription from "../../helpers/getArticleDescription"


/**
 * @param {*} groupBy : String ; valid values = [null, '', '<valid attribute in article>'] 
 */
export default function UserArticleResultList({ entries, fetchInProgress, cb_onShowMore, groupBy }) 
{
	const [numTextLines, setNumTextLines] = useState(4)
	const articles = entries.articles
	//
	const format_date = dateFormat()

	//#################################################################################
	useEffect(
		() => {
			window.addEventListener('resize', onWindowSize)
			onWindowSize()

			return () => {
				window.removeEventListener('resize', onWindowSize)
			}
		},
		[]
	)

	//#################################################################################
	const onWindowSize = () => {
		setNumTextLines( 
			window.innerWidth > 644 ? 4 : 3 		// 644 = 614 (desktop page width) + (2 * 15 (window scroll bar width))
		)
	}

	//#################################################################################
	const articleUrl = (article) => {
		return routes.article.replace(':topicId', article.id)
	}
	const quoteUrl = (article) => {
		return routes.quote.replace(':topicId', article.id)
	}

	//#################################################################################
	const render_article = (article) => {

		let header_img = StrapiImage.get1x1( article )
		let description = getArticleDescription(article)
		return (			
			<div className={classnames("card-component")}>
				<a href={articleUrl(article)}>
					<div className='article-container'>
						<CardMedia
							component="img"
							className="article-media"
							image={header_img}
							alt={article.title}
							data-scroll
						/>
						<div className="article-content">
							<div className='title-container'>
								<div className="title" dangerouslySetInnerHTML={{__html: article.title}} />
								<div className="date" data-scroll>{format_date.articlePreview_shortDate(article.visible_date)}</div>
							</div>
							{description.length > 0
								?	<div className="description">
										<TextTruncate
											line={numTextLines}
											element="span"
											truncateText="…"
											text={description}
											textTruncateChild={<span className="read-on">read more</span>}
										/> {/*<div className="description" dangerouslySetInnerHTML={{__html: description}} />*/}
									</div>
								: 	null
							}
						</div>
					</div>
				</a>
			</div>
		)
	}

	//#################################################################################
	const render_quote = (article) => {

		// console.log("article", article)

		const source = (article.quote_source || "").trim()
		return (			
			<div className={classnames("card-component", 'quote')}>
				<a href={quoteUrl(article)}>
					<div className='quote-container'>
						<div className="quote-title" dangerouslySetInnerHTML={{__html: article.quote_title}} />
						<div className="quote-cite" dangerouslySetInnerHTML={{__html: article.quote_cite}} />
						{source.length > 0
							? <div className="quote-src" dangerouslySetInnerHTML={{__html: source}} />
							: ''
						}
					</div>
				</a>
			</div>
		)
	}

	//#################################################################################
	const checkDate = (date1, date2) => {
		const a = new Date( date1 )
			a.setHours(0)
			a.setMinutes(0)
			a.setSeconds(0)
			a.setMilliseconds(0)
		const b = new Date( date2 )
			b.setHours(0)
			b.setMinutes(0)
			b.setSeconds(0)
			b.setMilliseconds(0)
		// console.log(`date1 ${a}, date2 ${b}`)
		return ( a < b
			? -1
			: ( a > b 
				? +1
				: 0
			)
		)
	}

	//#################################################################################
	const renderArticles_no_groupBy = () => {
		let result = articles.map((article, i) => {
			return (
				<div key={i} className={classnames("article-card")}>
					{ !article.isQuote
						? render_article(article)
						: render_quote(article)
					}
				</div>
			)
		})

		return result
	}

	//#################################################################################
	const renderArticles_groupBy = (groupBy) => {
		let date = null
		let result = []
		let key = 0
		
		for (let i = 0; i < articles.length; i++)
		{
			let article = articles[i]
			let curr_date = article[groupBy]
			if ( curr_date === null )
			{
				curr_date = article.visible_date
				console.error(`Error: Invalid 'groupBy' (=${groupBy}) value! Grouped by visible_date by default!`)
			}

			if ( i===0 || checkDate(date, curr_date)!==0 )
			{
				date = new Date( curr_date )
				result.push(
					<div key={key} className={classnames("groupBy", {"first": i === 0})}>
						{format_date.articlePreview_weekdayLong_monthInWords_noTime(curr_date)}
					</div>
				)
				key += 1
			}

			result.push(
				<div key={key} className={classnames("article-card")}>
					{ !article.isQuote
						? render_article(article)
						: render_quote(article)
					}
				</div>
			)
			key += 1
		}

		return result
	}

	//#################################################################################
	const renderArticles = () => {

		let group_by = (groupBy || '').trim()

		let result = ( group_by.length > 0 
			? renderArticles_groupBy( group_by )
			: renderArticles_no_groupBy()
		)
		/*
		let result = articles.map((article, i) => {
			return (
				<div key={i} className={classnames("article-card")}>
					{render_article(article)}
				</div>
			)
		})
		*/

		if ( cb_onShowMore )
		{
			// result.push( <div key='showMoreKey'><MainButton label="Show more" cb_onClick={(e) => {console.log("... on click")}} /></div> )
			result.push( 
				<div key='showMoreKey' className="button-container">
					<div className='button-wrapper'>
						<Button 
							// type="submit"
							className={classnames("button-root")}
							variant="contained"
							// startIcon={formData.serverRequestActive ? <CircularProgress /> : <Fingerprint />}
							disabled={false}
							onClick={(e) => { cb_onShowMore(e) }}
							disableElevation
							disableRipple
						>
							Load more
						</Button>
					</div>
				</div> 
			)
		}

		return result
	}

	//#################################################################################
	//#################################################################################
	//#################################################################################
	return (<>
		{/* <PaddingTop /> .... ist in der Page drin, weil diese Komponente nicht die komplette Page repräsentiert */}
		<div className="UserArticleResultList">
			<div className={classnames('wrapper-614')}>
				{
					typeof (articles) === 'undefined' || !Array.isArray(articles)
						? <span className={classnames("loading-msg", "normal-height")}>Loading ...</span>
						: ( Array.isArray(articles) && articles.length<=0
							? <span className={classnames("loading-msg", "normal-height")}>No articles found! {renderArticles()}</span>
							: <div className="articles-container">{renderArticles()}</div>
						)
				}
				{ fetchInProgress ? <LinearProgress className="progress-bar" /> : null }
			</div>
		</div>
	</>)
}

UserArticleResultList.propTypes = {
	entries: PropTypes.object,
	fetchInProgress: PropTypes.bool,
	cb_onShowMore: PropTypes.func,
	groupBy: PropTypes.string,
}
  
UserArticleResultList.defaultProps = {
	entries: [],
	fetchInProgress: false,
	cb_onShowMore: null,
	groupBy: null,
}
