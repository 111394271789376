
import Markdown from "markdown-it"
// import MarkdownSlack from "slack-markdown-it"
// import MarkdownTitle from "markdown-it-title"

const getArticleDescription = (article) => {
	const md = Markdown()
		// md.use( MarkdownSlack )
		// md.use( MarkdownTitle, {level: 0, excerpt: 0} )
	const raw_description = _getDescriptionText( article )
	const html_str = md.render( raw_description )
	return html_str.replaceAll(/<[^>]+>/ig, '')
}

const _getDescriptionText = (article) => {
	let subtitle = (article.description || '').trim()
	if ( subtitle.length > 0 )
	{
		return subtitle
	}
	const content = article.content || []
	subtitle = ''
	for (let each of content)
	{
		if ( each.__component.startsWith('content-modules.paragraph') )
		{
			subtitle += (subtitle.length>0 && !subtitle.endsWith(' ') ? ' ' : '') + each.text.trim()
		}
	}
	return subtitle
}

export default getArticleDescription