import { useEffect, useState, useRef } from "react"
// import { useParams /*, useNavigate*/ } from "react-router-dom"
// import PropTypes from 'prop-types'
import classnames from "classnames"

import dayjs from 'dayjs'

import './Calendar.scss'
import IconCalArrowLeft from "../../assets/icons/IconCalArrowLeft"
import IconCalArrowRight from "../../assets/icons/IconCalArrowRight"
import IconCalArrowDown from "../../assets/icons/IconCalArrowDown"

// import { makeStyles } from "@material-ui/core"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

import HtmlHeader from "../../components/HtmlHeader/HtmlHeader"
import PaddingTop from "../../components/PaddingTop/PaddingTop"
import CalendarDatePicker from "../../components/CalendarDatePicker/CalendarDatePicker"
import EventEntry from "./EventEntry/EventEntry"
import NoEventsEntry from "./NoEventsEntry/NoEventsEntry"
import NextEventDayHeader from "./NextEventDayHeader/NextEventDayHeader"

import cmsConnect from "../../helpers/cmsConnect"
import localData from "../../helpers/localData"
import routes from "../../routes/routes"
import dateFormat from "../../helpers/dateFormat"
import JSONEnDecoder from "../../helpers/JSONEnDecoder"



const FADE_OUT_DURATION = 100		// Angabe in ms; ACHTUNG! dieser Wert muß mit dem Wert in EventEntry.scss >> $fadeOut_duration entsprechen
const FADE_IN_DELAY = 0.05			// Angabe in s

const FILTER_SEPARATOR = ';'


/*
// https://codesandbox.io/s/pedantic-ellis-y3buk?fontsize=14&hidenavigation=1&theme=dark&file=/src/App.js:141-331
// https://stackoverflow.com/questions/55446073/how-to-overried-the-selected-classes-in-menuitem-in-material-ui-reactjs
// https://codesandbox.io/s/k096no1qkv?fontsize=14
const useStyles = makeStyles((theme) => ({
	// list: {
	// 	padding: 0,
	// 	background: '#f00'
	// },
	menuItem_root: {
		background: '#fc0 !important',
		// "&$menuItem_selected, &$menuItem_selected:focus, &$menuItem_selected:hover": {
     	// 	backgroundColor: "red"
    	// }
		"&:hover": {
			background: '#f90 !important',
		}
	},
	menuItem_selected: {
		background: '#ff0 !important'
	}
}))
//....... <MenuItem value="none" classes={{root: classes.menuItem_root, selected: classes.menuItem_selected}}><em>Location</em></MenuItem>
*/


export default function Calendar() 
{
	// const classes = useStyles()

	const [dayEvents, setDayEvents] = useState({
		day_events: [], 
		next_events: {
			dayOrder: [], 
			dayEvents: {}
		}
	})
	const [monthEvents, setMonthEvents] = useState({})
	const [filters, setFilters] = useState({locations: [], locations_dic: {}, types: [], types_dic: {}})
	const [filterLocation, setFilterLocation] = useState([])
	const [filterTypes, setFilterTypes] = useState([])
	const [date, setDate] = useState(new Date())
	const [minDate] = useState(dayjs('2022-09-01').toDate())
	const [maxDate] = useState(dayjs('1900-12-31').year(dayjs().year()).add(2, 'year').toDate())		// == (31.12.<aktuelles Jahr>) + 2 Jahre

	const prevDayButtonRef = useRef()
	const nextDayButtonRef = useRef()
	const eventListRef = useRef()

	const format_date = dateFormat()

	//#################################################################################
	useEffect(
		() => {
			//-------------------------------------------------------------------------
			setHashParams()
		}, 
		[]
	)

	useEffect(
		() => {
			//-------------------------------------------------------------------------
			fetchEvents(date, true, true, true)

			//-------------------------------------------------------------------------
			updateDateNavButtons( date )
		}, 
		[date, filterLocation, filterTypes]
	)

	//#################################################################################
	const setHashParams = () => 
	{
		let init_date = date

		if ( document.location.hash.length > 0 )
		{
			const PARAM = 'date'
			const query = document.location.hash.substring(1)
			if ( !query.startsWith(PARAM) ) return init_date	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
			let date_str = query.substring(PARAM.length)
			if ( /\d{8}(|.+)*/i.test(date_str) === false ) return init_date		// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
			init_date = dayjs(date_str.substring(0,8), 'YYYYMMDD')
			//-----------------------------------------
			// Das Datum MUSS vor den Filtern gesetzt werden (auch wenn der Wert async. gesetzt wird)
			setDate(init_date.toDate())
			//-----------------------------------------

			let data_encoded = date_str.substring(8)
			if ( data_encoded.startsWith('|') )
			{
				data_encoded = data_encoded.substring(1)
				const data = JSONEnDecoder.decodeJSON(data_encoded)
				if ( typeof(data)==='object' && data!==null )
				{
					if ( typeof(data.fl)!=='undefined' && data.fl!==null && data.fl ) setFilterLocation( data.fl )
					if ( typeof(data.ft)!=='undefined' && data.ft!==null && data.ft ) setFilterTypes( data.ft )
				}
			}
		}

		return init_date
	}

	//#################################################################################
	const fetchEvents = async (date, getMonthEvents, getDayEvents, getFilters, delay = 0) => {
		
		// console.log(`>>>>>>> fetchEvents(${delay}) START`, dayjs(date).format('YYYY-MM-DD'))

		setTimeout(async () => {
			const local_time_zone = dayjs().format('Z')
			const param_filter_loc = ( typeof(filterLocation)!=='undefined' && filterLocation!==null && filterLocation.length>0
				? '&fl=' + encodeURIComponent(filterLocation.join(','))
				: ''
			)
			const param_filter_type = ( typeof(filterTypes)!=='undefined' && filterTypes!==null && filterTypes.length>0
				? '&ft=' + encodeURIComponent(filterTypes.join(','))
				: ''
			)
			// const res = await cmsConnect().GET(`/api/v/events?d=${dayjs(date).format('YYYY-MM-DD')}&t=${getMonthEvents?'m':''}${getDayEvents?'d':''}&tz=${encodeURIComponent(local_time_zone)}`)
			await cmsConnect().GET_queue(
				`/api/v/events?d=${dayjs(date).format('YYYY-MM-DD')}&t=${getFilters?'f':''}${getMonthEvents?'m':''}${getDayEvents?'d':''}&tz=${encodeURIComponent(local_time_zone)}${param_filter_loc}${param_filter_type}`,
				(res) => {
					if ( typeof(res)!=='undefined' && typeof(res.data)!=='undefined' ) 
					{
						// console.log(">>>>>> fetchEvents :: res", res.data)

						//--------------------------------------------------------------------- set day events
						if ( getDayEvents )
						{
							// setDayEvents( res.data.day || [] )

							const nextEvents = __groupNextEventsByDay(res.data.nextEvents || [])
							// console.log("---------------------------------------------")
							// console.log(res.data.nextEvents)
							// console.log(">> nextEvents", nextEvents)
							fadeInCurrentEventList({
								day_events:		(res.data.day || []),
								next_events:	nextEvents
							})
						}
						//--------------------------------------------------------------------- set month events
						if ( getMonthEvents )
						{
							setMonthEvents( res.data.month || [] )
						}
						//--------------------------------------------------------------------- set filter data (location + type)
						if ( getFilters )
						{
							let data = res.data.filters || {}
							
							let l_dic = {}
							for (let each of data.locations)
							{
								l_dic[each.id] = each
							}
							data.locations_dic = l_dic

							let t_dic = {}
							for (let each of data.types)
							{
								t_dic[each.id] = each
							}
							data.types_dic = t_dic

							setFilters( data )
						}
					}
				}
			)
		}, delay)
	}

	//#################################################################################
	const __groupNextEventsByDay = (nextEvents) => {

		let current_day = null
		let current_day_key = null
		let dayOrder = []
		let dayEvents = {}
		let dayEventList = []

		function pushDayEvents()
		{
			if ( current_day_key!==null && dayEventList.length>0 )
			{
				dayOrder.push( current_day_key )
				dayEvents[current_day_key] = dayEventList
			}
		}

		for (let eachEvent of nextEvents)
		{
			const eventDate = dayjs(dayjs(eachEvent.date_from).format('YYYY-MM-DD'))		// dayjs(eachEvent.date_from, 'YYYY-MM-DD') verwendet leider auch die Uhrzeit, ich brauche hier nur das Datum, deshalb dayjs(eachEvent.date_from).format('YYYY-MM-DD')
			const dayKey = eventDate.format('YYYY-MM-DD')
			if ( current_day===null || eventDate.isSame(current_day)===false )
			{
				pushDayEvents()	
				current_day		= eventDate
				current_day_key	= dayKey
				dayEventList = [ eachEvent ]
			}
			else
			{
				dayEventList.push( eachEvent )
			}
		}
		pushDayEvents()

		return {
			dayOrder: dayOrder,
			dayEvents: dayEvents,
		}
	}

	//#################################################################################
	const fadeInCurrentEventList = (newDayEvents) => {

		//------------------------------------------------- fade out
		const t_fadeOut = 10
		// const t_fadeOut = FADE_OUT_DURATION
		// if ( eventListRef.current.children.length )
		// {
		// 	for(let each of eventListRef.current.children)
		// 	{
		// 		each.classList.add('fadeOut')
		// 	}
		// }

		//------------------------------------------------- fade in
		setTimeout(() => {
			//---------------------- reset animation
			for(let each of eventListRef.current.children)
			{
				each.classList.remove('fadeIn', 'fadeOut')
			}
			//---------------------- fade in
			setTimeout(() => {
				setDayEvents(newDayEvents)

				for(let each of eventListRef.current.children)
				{
					each.classList.add('fadeIn')
				}
			}, 100)
		}, t_fadeOut)
	}

	//#################################################################################
	const onDateSelected = (newDate) => {

		// console.log(">>>>>>>> onDateSelected :: oldDate\n", date, "\n", newDate)
		// const od = dayjs(date)
		// const nd = dayjs(newDate)
		// const updateMonth = ( od.month()!==nd.month || od.year()!==nd.year() )
		//
		// fetchEvents(newDate, updateMonth, true, false) ... wird autom. in useEffect([date, filterLocation, filterTypes]) ausgeführt, wenn setDate(..) ausgeführt wird
		//
		setDate( newDate )
		update_hash(newDate, filterLocation, filterTypes)
		updateDateNavButtons( newDate )

		// console.error("------------------------------------------\nWenn onDateSelected() aufgerufen wird, dann Y-Pos. von den Fixed-Layer reseten ... wenn man hochscrollt und danach einennneuen Tag auswählt, wird der Content-Block an der richtigen stelle angezeigt, aber die Fixed-Layer nicht")
	}

	//#################################################################################
	const onMonthYearChanged = (date) => {
		// console.log(">>>>>>>> onMonthYearChanged", date)
		fetchEvents(date, true, false, false)
	}

	//#################################################################################
	// const getHighlightedText = () => {
	// 	var text = ""
	// 	if (window.getSelection) { text = window.getSelection().toString() } 
	// 	else if (document.selection && document.selection.type != "Control") { text = document.selection.createRange().text }
	// 	return text
	// }
	const clearHighlightedText = () => {

		if (window.getSelection) { window.getSelection().removeAllRanges() } 
		else if (document.selection) { document.selection.empty() }
	}

	//#################################################################################
	const is_newDate_outOfBounds = (newDate) => {
		let result = {
			outOfBounds: false,
			isPrevDayAvailable: false,
			isNextDayAvailable: false,
		}
		const woT_newDate = dayjs(newDate).hour(0).minute(0).second(0).millisecond(0).toDate()	// w/o time
		const woT_minDate = dayjs(minDate).hour(0).minute(0).second(0).millisecond(0).toDate()	// w/o time
		const woT_maxDate = dayjs(maxDate).hour(0).minute(0).second(0).millisecond(0).toDate()	// w/o time
		
		result.outOfBounds = ( woT_minDate>woT_newDate || woT_maxDate<woT_newDate )

		const prevDate = dayjs(woT_newDate).add(-1, 'day').toDate()
		const nextDate = dayjs(woT_newDate).add(+1, 'day').toDate()

		result.isPrevDayAvailable = ( woT_minDate <= prevDate )
		result.isNextDayAvailable = ( woT_maxDate >= nextDate )
		
		return result
	}

	//#################################################################################
	const update_hash = (newDate, filter_location, filter_types) => {
		const dict = {
			fl: filter_location,
			ft: filter_types
		}
		const encoded = JSONEnDecoder.encodeJSON(dict)
		document.location.hash = `date${dayjs(newDate).format('YYYYMMDD')}|${encoded}`
	}

	//#################################################################################
	const updateDateNavButtons = ( date ) => {

		let dateBounds = is_newDate_outOfBounds(date)
		let buttonRef

		buttonRef = prevDayButtonRef.current
		if ( dateBounds.isPrevDayAvailable === false )
		{
			if ( buttonRef.classList.contains('disabled') === false )
			{
				buttonRef.classList.add('disabled')
			}
		}
		else
		{
			if ( buttonRef.classList.contains('disabled') )
			{
				buttonRef.classList.remove('disabled')
			}
		}

		buttonRef = nextDayButtonRef.current
		if ( dateBounds.isNextDayAvailable === false )
		{
			if ( buttonRef.classList.contains('disabled') === false )
			{
				buttonRef.classList.add('disabled')
			}
		}
		else
		{
			if ( buttonRef.classList.contains('disabled') )
			{
				buttonRef.classList.remove('disabled')
			}
		}
	}

	//#################################################################################
	const onClick_dateNavButton = (direction) => {

		//----------------------------------------------------------
		// wenn man den previous-day/next-day zu schnell klickt (do schnell oder schneller wie bei einem Doppelklick), dann wird ...
		// das Datum (<weekday>, <month> <date>) autom. markiert. Um diese Effekt zu unterdrücken, muss ich die autom. Selektion
		// vom Text entfernen
		//-----------------------------
		clearHighlightedText()
		//----------------------------------------------------------

		const newDate = dayjs(date).add(direction, 'day').toDate()
		const bounds = is_newDate_outOfBounds(newDate)
		if ( bounds.outOfBounds )
		{
			return
		}
		onDateSelected(newDate)
	}

	//#################################################################################
	const onClick_showEventDetail = (event) => {

		const dict = {
			fl: filterLocation,
			ft: filterTypes
		}
		const encoded = JSONEnDecoder.encodeJSON(dict)
		window.location.href = routes.calendar_detail.replace(':eventId', event.id) + `#cal-date${dayjs(date).format('YYYYMMDD')}|${encoded}`
	}

	//#################################################################################
	const onChange_filterLocation = (event) => {
		const {
			target: { value },
		} = event
		setFilterLocation(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(FILTER_SEPARATOR) : value,
			// value
		);
	}

	//#################################################################################
	const onChange_filterTypes = (event) => {
		const {
			target: { value },
		} = event
		setFilterTypes(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(FILTER_SEPARATOR) : value,
			// value
		);
	}

	//#################################################################################
	const onClose_filters = () => {
		// fetchEvents(date, true, true, true)	... wurde schon in useEffect([date, filterLocation, filterTypes]) ausgeführt, wenn ein Filter verändert wurde
		update_hash(date, filterLocation, filterTypes)
		updateDateNavButtons( date )
	}

	//#################################################################################
	const renderValue_filters = (selected, placeholder, id2name_dic) => {
		// console.log(`>>>>> renderValue :: typeof(selected)=[${typeof(selected)}] selected=[${selected}]`)
		if ( typeof(selected)==='undefined' || selected===null || selected==='' || (Array.isArray(selected) && selected.length===0) ) 
		{
			return ( <em>{placeholder}</em> )
		}
		if ( typeof(selected)==='string' )
		{
			return selected
		}
		// ... else if ( typeof(selected)==='object' )		// ... Array
		let result = []
		for (let each_id of selected)
		{
			let v = id2name_dic[each_id]
			if ( typeof(v)!=='undefined' && v!==null )
			{
				result.push( v.name )
			}
		}
		return result.join(`${FILTER_SEPARATOR} `)
		// return selected.join(`${FILTER_SEPARATOR} `)
	}

	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	if ( localData().getCustomer() == null )
	{
		// navigate( routes.login )
		routes.navToRoute( routes.login )
		return <></>
	}
	return <>
		<HtmlHeader subtitle="Calendar" />
		<PaddingTop />

		<div className="Calendar">
			<div className={classnames('wrapper')}>
				<div className="inner-wrapper">
					{/* ------------------------------------------------------------------------------------------- header */}
					<div className="header">
						<h1>Culture Calendar</h1>
					</div>
					<div className="root-container">
						{/* ======================================================================================= left container */}
						<div className="cal-container">
							{/* ----------------------------------------------------------------------------------- calendar picker */}
							<div className="filter-container">
								<FormControl className="filter-elem" size="small">
									<InputLabel>Location</InputLabel>
									<Select
										label="location"
										IconComponent={IconCalArrowDown}
										onChange={onChange_filterLocation}
										onClose={onClose_filters}
										multiple
										displayEmpty
          								value={filterLocation}
										renderValue={(selected) => {
											// console.log(`>>>>> renderValue :: typeof(selected)=[${typeof(selected)}] selected=[${selected}]`)
											return renderValue_filters(selected, 'All locations', filters.locations_dic)
										}}
										// MenuProps={{ classes: { list: classes.list } }}
									>
										{/* https://mui.com/material-ui/api/menu-item/ */}
										{filters.locations.map((loc, i) => (
											<MenuItem 
												key={`loc_${i}`} 
												value={loc.id}
												sx={{
													paddingLeft: '6px',
													// '&.classs': {color: '#000'}
												}}
											>
												<Checkbox checked={filterLocation.indexOf(loc.id) > -1} size="small" />
												<ListItemText primary={loc.name} primaryTypographyProps={{fontSize: 12}} />
											</MenuItem>
										))}
										{/* <MenuItem value="none"><em>Location</em></MenuItem>
										<MenuItem value={10}>Mailand, IT</MenuItem>
										<MenuItem value={20}>München, Deutschland</MenuItem>
										<MenuItem value={30}>Berlin</MenuItem> */}
									</Select>
								</FormControl>
								<FormControl className="filter-elem" size="small">
									<InputLabel>Event Type</InputLabel>
									<Select
										label="location"
										IconComponent={IconCalArrowDown}
										onChange={onChange_filterTypes}
										onClose={onClose_filters}
										multiple
										displayEmpty
          								value={filterTypes}
										renderValue={(selected) => {
											// console.log(`>>>>> renderValue :: typeof(selected)=[${typeof(selected)}] selected=[${selected}]`)
											return renderValue_filters(selected, 'All event types', filters.types_dic)
										}}
										// MenuProps={{ classes: { list: classes.list } }}
									>
										{filters.types.map((t, i) => (
											<MenuItem 
												key={`type_${i}`} 
												value={t.id}
												sx={{
													paddingLeft: '6px',
													// '&.classs': {color: '#000'}
												}}
											>
												<Checkbox checked={filterTypes.indexOf(t.id) > -1} size="small" />
												<ListItemText primary={t.name} primaryTypographyProps={{fontSize: 12}} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<CalendarDatePicker 
								date={date} 
								cb_onDateSelected={onDateSelected} 
								cb_onMonthYearChanged={onMonthYearChanged}
								minDate={minDate}
								maxDate={maxDate}
								daysWithEvents={monthEvents}
							/>
						</div>
						{/* ======================================================================================= right container */}
						<div className="right-container">
							{/* ----------------------------------------------------------------------------------- day + day nav */}
							<div className={classnames("day-outer-container")}>
								<div className="day-inner-container">
									<div className="current-date">{format_date.calendar_weekdayLong_monthInWords_dateOnly_noTime(date)}</div>
									<div className="day-nav-container">
										<div 
											className="button previous-day" 
											ref={prevDayButtonRef} 
											onClick={e => onClick_dateNavButton(-1)}
										>
											<IconCalArrowLeft className="arrow left" />
										</div>
										<div 
											className="button next-day" 
											ref={nextDayButtonRef} 
											onClick={e => onClick_dateNavButton(+1)}
										>
											<IconCalArrowRight className="arrow right" />
										</div>
									</div>
								</div>
							</div>
							{/* ----------------------------------------------------------------------------------- event list */}
							<div className={classnames("content-root-container", "event-group")}>
								<div className="content-container">
									<div className="event-root-container">
										<div className="event-list" ref={eventListRef}>
											{dayEvents.day_events.length > 0
												?	dayEvents.day_events.map((e, i) => {
														return <EventEntry key={`event_${i}`} data={e} fadeInDelay={FADE_IN_DELAY * i} cb_onClick={onClick_showEventDetail} />
														// return (<>
														// 	<EventEntry key={`event_${i*5}`} data={e} fadeInDelay={FADE_IN_DELAY * i} cb_onClick={onClick_showEventDetail} />
														// 	<EventEntry key={`event_${i*5+1}`} data={e} fadeInDelay={FADE_IN_DELAY * i} cb_onClick={onClick_showEventDetail} />
														// 	<EventEntry key={`event_${i*5+2}`} data={e} fadeInDelay={FADE_IN_DELAY * i} cb_onClick={onClick_showEventDetail} />
														// 	<EventEntry key={`event_${i*5+3}`} data={e} fadeInDelay={FADE_IN_DELAY * i} cb_onClick={onClick_showEventDetail} />
														// 	<EventEntry key={`event_${i*5+4}`} data={e} fadeInDelay={FADE_IN_DELAY * i} cb_onClick={onClick_showEventDetail} />
														// </>)
													})
												:	<NoEventsEntry />
											}
										</div>
									</div>
								</div>
							</div>
							{/* ----------------------------------------------------------------------------------- upcomming events */}
							{ dayEvents.next_events.dayOrder.length <= 0
								?	null
								:	dayEvents.next_events.dayOrder.map((e,i) => {

										const day_event_list = dayEvents.next_events.dayEvents[e]
										if ( day_event_list.length <= 0 ) return null	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!

										return <>
											{/* ------------------------------------------------------ upcoming events: day header */}
											<NextEventDayHeader key={`${e}_${i}`} date={e} showUpcomingEventsHeader={i===0} /> 
											{/* ------------------------------------------------------ upcoming events: day events */}
											<div className={classnames("upcoming-events-content-root-container", "event-group")}>
												<div className="content-container">
													<div className="event-root-container">
														<div className="event-list" /*ref={eventListRef}*/>
															{ day_event_list.map( (de,j) => {
																return <EventEntry key={`upcoming_event_${i}_${j}`} data={de} fadeInDelay={0} cb_onClick={onClick_showEventDetail} />
															}) }
														</div>
													</div>
												</div>
											</div>
										</>
									})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

Calendar.propTypes = {
	// entries: PropTypes.object,
	// fetchInProgress: PropTypes.bool,
	// cb_onShowMore: PropTypes.func,
}
  
Calendar.defaultProps = {
	// entries: [],
	// fetchInProgress: false,
	// cb_onShowMore: null,
}