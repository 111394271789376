import React from "react"
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useRef } from "react"
// import { useNavigate } from "react-router-dom"
import classnames from "classnames"

import "./UserArticleOverview.scss"
// import MainArrowButton from "../MainIconButton/MainIconButton"
// import IconArrowDown from "../../assets/icons/IconArrowDown"
import PaddingTop from "../PaddingTop/PaddingTop"
// import TextTruncate from 'react-text-truncate'
import TextTruncate from '../TextTruncate/TextTruncate'

// UI components
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'

// tools
import dateFormat from "../../helpers/dateFormat"
import routes from "../../routes/routes"
import ScrollOutUtils from "../..//helpers/ScrollOutUtils"
import ScrollOut from "scroll-out"
import StrapiImage from "../../helpers/StrapiImage"
import getArticleDescription from "../../helpers/getArticleDescription"


export default function UserArticleOverview({ entries, fetchInProgress, cb_onShowMore }) 
{
	const INSERT_QUOTE_0_AT_IDX = 7
	const INSERT_QUOTE_n_AT_IDX = 6
	//
	const [numTextLines, setNumTextLines] = useState(5)
	const [is2ColModeActive, setIs2ColModeActive] = useState(false)
	const articles = entries.articles
	const quotes = entries.quotes
	const NUM_ARTICLES_IN_PAGE_0 = entries.MAX_PAGE_0_SIZE || 10
	const NUM_ARTICLES_IN_PAGE_n = entries.MAX_PAGE_n_SIZE || 12
	//
	// const navigate = useNavigate()
	// const API_URL = process.env.REACT_APP_API_URL
	const format_date = dateFormat()
	//
	const wrapperRef = useRef()

	//#################################################################################
	useEffect(
		() => {
			/*
			https://oxygen4fun.supadezign.com/tutorials/scroll-animations-with-scrollout/
			*/

			// const so = ScrollOutUtils.init()
			const so = ScrollOut({
				once: true,
				cssProps: {
					viewportX: true,		// die Variable --viewport-x in CSS exportieren (Zugriff mit var(--viewport-x), wenn data-scroll in dem entsprechenden Tag angegeben wurde)
					// viewportY: true,		// die Variable --viewport-y in CSS exportieren
					// offsetX: true,		// die Variable --offset-x in CSS exportieren
				},
			})
			return () => {
				so.teardown();
			}
		}
	)
	useEffect(
		() => {
			window.addEventListener('resize', onWindowSize)
			onWindowSize()

			return () => {
				window.removeEventListener('resize', onWindowSize)
			}
		},
		[]
	)

	//#################################################################################
	const onWindowSize = () => {
		setNumTextLines( 
			window.innerWidth > 1185 ? 5 : 3 		// 1185 = 1170 (desktop page width) + 15 (window scroll bar width)
		)
		setIs2ColModeActive(
			window.innerWidth>540 && window.innerWidth<=780 ? true : false		// 780 = 765 (desktop page width) + 15 (window scroll bar width), 540 = 1 Col Mode (Mobile) 
		)
	}

	//#################################################################################
	/*
	const navToArticle = (article) => {
		// setTimeout(() => {routes.navToArticleId(article.id)}, 500)
		// setTimeout( () => {navigate( routes.article.replace(':topicId', article.id) )}, 500 )
		navigate( routes.article.replace(':topicId', article.id) )
	}
	*/
	const articleUrl = (article) => {
		return routes.article.replace(':topicId', article.id)
	}
	const quoteUrl = (article) => {
		return routes.quote.replace(':topicId', article.id)
	}

	//#################################################################################
	const render_article_hero = (article) => {

		let header3x2_img = StrapiImage.get3x2( article )
		let header16x9_img = StrapiImage.get16x9( article )
		let header1x1_img = StrapiImage.get1x1( article )
		let subtitle = getArticleDescription(article)
		return (
			<a href={articleUrl(article)}>
				<div 
					className={classnames("card-component")}
					// onClick={() => navToArticle(article)}
				>
					<div className="hero-container">
						<div className="hero-img-container">
							<CardMedia
								component="img"
								className="card-media size3x2"
								image={header3x2_img}
								alt={article.title}
							/>
							<CardMedia
								component="img"
								className="card-media size16x9"
								image={header16x9_img}
								alt={article.title}
							/>
							<CardMedia
								component="img"
								className="card-media size1x1"
								image={header1x1_img}
								alt={article.title}
							/>
						</div>
						<div className="title-container">
							<div className="title" dangerouslySetInnerHTML={{__html: article.title}} />
							{subtitle.length > 0
								?	<div className="subtitle">
										<TextTruncate
											line={numTextLines}
											element="span"
											truncateText="…"
											text={subtitle}
											textTruncateChild={<span className="read-on">»</span>}
										/> {/*<div className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />*/}
									</div>
								: 	null
							}
							<div className="date">{format_date.articlePreview_shortDate(article.visible_date)}</div>
						</div>
					</div>
				</div>
			</a>
		)
	}

	//#################################################################################
	const render_article = (article) => {

		let header_img = StrapiImage.get1x1( article )
		return (
			<a href={articleUrl(article)}>
				<div 
					className={classnames("card-component")}
					// onClick={() => navToArticle(article)}
					data-scroll
				>
					<div className="img-container">
						<CardMedia
							component="img"
							className="card-media"
							image={header_img}
							alt={article.title}
							data-scroll
						/>
					</div>
					<div className="card-content">
						<div className="title" data-scroll dangerouslySetInnerHTML={{__html: article.title}} />
						<div className="date" data-scroll>{format_date.articlePreview_shortDate(article.visible_date)}</div>
					</div>
				</div>
			</a>
		)
	}

	//#################################################################################
	const render_quote = (quote, quote_idx) => {
		const quote_src = (quote.quote_source || '').trim()
		return (
			<div key={`quote@idx${quote_idx}`} className={classnames("article-card", "quote")}>
				<a href={quoteUrl(quote)}>
					<div 
						className={classnames("quote-component")}
						// onClick={() => navToArticle(article)}
					>
						<div className="quote-content" data-scroll>
							<div className="quote-title" dangerouslySetInnerHTML={{__html: quote.quote_title}} />
							<div className="quote-body">
								<div className="cite" dangerouslySetInnerHTML={{__html: quote.quote_cite}} />
								{ quote_src.length > 0
									? <div className="source" dangerouslySetInnerHTML={{__html: quote_src}} />
									: null
								}
							</div>
						</div>
					</div>
				</a>
			</div>
		)
	}

	//#################################################################################
	const renderArticles = () => {

		let result = articles.map((article, i) => {
			return (
				<div key={i} className={classnames("article-card", {"hero": (i<=0), "default": (i>0)})}>
					{i <= 0
						? render_article_hero(article)
						: render_article(article)
					}
				</div>
			)
		})

		if ( typeof(quotes)!='undefined' && quotes!=null && quotes.length>0 )
		{
			/*
			const html_quote = (
				<div key={'quote@idx7'} className={classnames("article-card", "quote")}>
					{render_quote(quotes[0], 0)}
				</div>
			)
			if ( result.length > 7 )
			{
				result.splice( 7, 0, html_quote )
			}
			else
			{
				result.push( html_quote )
			}
			*/
			if ( result.length > INSERT_QUOTE_0_AT_IDX )
			{
				for (let i = 0, page_idx = 0; i < quotes.length; i++ )
				{
					const html_quote = render_quote( quotes[i], i )
					if ( i == 0 )
					{
						result.splice( INSERT_QUOTE_0_AT_IDX, 0, html_quote )
						page_idx += NUM_ARTICLES_IN_PAGE_0 + 1			// = NUM_ARTICLES_IN_PAGE_0 (nur Articles) + 1 (ein Quote)
					}
					else
					{
						result.splice( (page_idx + (INSERT_QUOTE_n_AT_IDX + (is2ColModeActive?1:0))), 0, html_quote )
						page_idx += NUM_ARTICLES_IN_PAGE_n + 1			// = NUM_ARTICLES_IN_PAGE_n (nur Articles) + 1 (ein Quote)
					}
				}
			}
			else
			{
				// wenn result (d.h. Artikeln und quotes zusammen in einem Array) <= INSERT_QUOTE_0_AT_IDX, dann einfach den ersten Quote am Ende der Liste einfügen
				result.push( render_quote(quotes[0], 0) )
			}
		}

		if ( cb_onShowMore )
		{
			// result.push( <div key='showMoreKey'><MainButton label="Show more" cb_onClick={(e) => {console.log("... on click")}} /></div> )
			result.push( 
				<div key='showMoreKey' className="button-container">
					<Button 
						// type="submit"
						className={classnames("button-root")}
						variant="contained"
						// startIcon={formData.serverRequestActive ? <CircularProgress /> : <Fingerprint />}
						disabled={false}
						onClick={(e) => { cb_onShowMore(e) }}
						disableElevation
						disableRipple
					>
						Load more
					</Button>
				</div> 
			)
		}

		wrapperRef.current.classList.remove('height100vh')

		return result 
	}

	//#################################################################################
	//#################################################################################
	//#################################################################################
	// console.log(">>>>>>>>>>>>>>> articles", typeof(articles), articles)
	// console.log(">>>>>>>>>>>>>>> quotes", quotes)
	// console.log(">>>>>>>>>>>>>>> NUM_ARTICLES_IN_PAGE_0:", NUM_ARTICLES_IN_PAGE_0, ", NUM_ARTICLES_IN_PAGE_n:", NUM_ARTICLES_IN_PAGE_n)
	return (<>
		<PaddingTop />
		<div className="UserArticleOverview">
			<div ref={wrapperRef} className={classnames('wrapper', "height100vh")}>
				{
					typeof (articles) === 'undefined' || !Array.isArray(articles)
						? <span className="loading-msg">Loading ...</span>
						: ( Array.isArray(articles) && articles.length<=0
							? <span className={classnames("loading-msg", "normal-height")}>No articles found! {renderArticles()}</span>
							: <div className="articles-container">{renderArticles()}</div>
						)
				}
				{ fetchInProgress ? <LinearProgress className="progress-bar" /> : null }
			</div>
		</div>
	</>)
}

UserArticleOverview.propTypes = {
	entries: PropTypes.object,
	fetchInProgress: PropTypes.bool,
	cb_onShowMore: PropTypes.func,
}
  
UserArticleOverview.defaultProps = {
	entries: [],
	fetchInProgress: false,
	cb_onShowMore: null,
}