
// import classnames from 'classnames'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

// helper
import { Helmet } from "react-helmet"

export default function HtmlHeader({subtitle, og_image}) 
{
	const customer = useSelector((redux) => redux.customer.value)
	const path = process.env.REACT_APP_API_URL

	//#################################################################################
	useEffect(
		() => {},
		[]
	)

	//#################################################################################
	//#################################################################################
	//#################################################################################

	const title = ( customer == null
		? 'Relevance Hub'
		: customer.hub_name
	)

	let browser_icons = []
	let og_optimization = []
	if ( customer != null )
	{
		let browserIcon_obj = customer.browser_icon || {}
		let links_array = browserIcon_obj.links || []
		browser_icons = links_array.map((e, i) => {
			return (
				<link key={i} rel={e.rel} type={e.type} sizes={e.sizes} href={path + e.href} />
			)
		})

		const open_graph_obj = browserIcon_obj.open_graph || {}
		const og_default_image = open_graph_obj.default_image || null
		og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:type" content="website" /> )
		// og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:title" content={`${title}${subtitle ? ` | ${subtitle}` : ''}`} /> )
		og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:title" content={`${title}`} /> )
		og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:description" content={`${subtitle && subtitle.length>0 ? `${subtitle}` : ''}`} /> )
		og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:url" content={window.location.href} /> )
		if ( typeof(og_image)!=='undefined' && og_image!==null )
		{
			og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:image" content={og_image} /> )
			og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:image:url" content={og_image} /> )
			og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:image:secure_url" content={og_image} /> )
		}
		else if ( og_default_image !== null )
		{
			og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:image" content={path + og_default_image} /> )
			og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:image:url" content={path + og_default_image} /> )
			og_optimization.push( <meta key={'og_'+og_optimization.length} property="og:image:secure_url" content={path + og_default_image} /> )
		}
	}	

	// <meta property="og:url" content="https://www.spiegel.de/">
	// <meta property="og:image" content="https://www.spiegel.de/public/spon/images/logos/fb_logo_default.jpg" 
	return <>
		<Helmet>
			<meta charSet="utf-8" />
			<title>{title}{subtitle ? ` | ${subtitle}` : ''}</title>
			{ browser_icons.length > 0 ? browser_icons : null }
			{ og_optimization.length > 0 ? og_optimization : null }
		</Helmet>
	</>
}
 