
//#################################################################################
class TargetURL
{
	static test_getTargetURL()
	{
		let res, str, err
		str = 'https://google.com'; 						err = ( (res=this.getTargetURL(str)) !== str ); console.log(`++ test [${str}] =>`, (err ? `error :: ${res}`: 'ok'))
		str = 'https://google.com?test=abc';				err = ( (res=this.getTargetURL(str)) !== str ); console.log(`++ test [${str}] =>`, (err ? `error :: ${res}`: 'ok'))
		str = 'http://localhost:3000';						res = this.getTargetURL(str); err = ( !/^http:\/\/localhost:3000\/?\?showWelcomeMsgPreviewID=wmid\d+$/im.test(res) ); console.log(`++ test [${str}] =>`, (err ? `error :: [${res}]`: 'ok'))
		str = 'http://localhost:3000?test=abc';				res = this.getTargetURL(str); err = ( !/^http:\/\/localhost:3000\/?\?test=abc&showWelcomeMsgPreviewID=wmid\d+$/im.test(res) ); console.log(`++ test [${str}] =>`, (err ? `error :: [${res}]`: 'ok'))
		str = 'http://localhost:3000/article/406';			res = this.getTargetURL(str); err = ( !/^http:\/\/localhost:3000\/article\/406\/?\?showWelcomeMsgPreviewID=wmid\d+$/im.test(res) ); console.log(`++ test [${str}] =>`, (err ? `error :: [${res}]`: 'ok'))
		str = 'http://localhost:3000/article/406?test=abc';	res = this.getTargetURL(str); err = ( !/^http:\/\/localhost:3000\/article\/406\/?\?test=abc&showWelcomeMsgPreviewID=wmid\d+$/im.test(res) ); console.log(`++ test [${str}] =>`, (err ? `error :: [${res}]`: 'ok'))
		str = '/';											res = this.getTargetURL(str); err = ( !/^\/\?showWelcomeMsgPreviewID=wmid\d+$/im.test(res) ); console.log(`++ test [${str}] =>`, (err ? `error :: [${res}]`: 'ok'))
		str = '/?test=abc';									res = this.getTargetURL(str); err = ( !/^\/\??test=abc&showWelcomeMsgPreviewID=wmid\d+$/im.test(res) ); console.log(`++ test [${str}] =>`, (err ? `error :: [${res}]`: 'ok'))
		str = '/article/407';								res = this.getTargetURL(str); err = ( !/^\/article\/407\/?\?showWelcomeMsgPreviewID=wmid\d+$/im.test(res) ); console.log(`++ test [${str}] =>`, (err ? `error :: [${res}]`: 'ok'))
		str = '/article/407?test=abc';						res = this.getTargetURL(str); err = ( !/^\/article\/407\/?\?test=abc&showWelcomeMsgPreviewID=wmid\d+$/im.test(res) ); console.log(`++ test [${str}] =>`, (err ? `error :: [${res}]`: 'ok'))
	}

	//#################################################################################
	// * = local url
	// isPreviewMode == true
	// 		https://google.com								=> https://google.com
	//		https://google.com?test=abc						=> https://google.com?test=abc
	//		* https://mediacom.relevancehub.net 			=> https://mediacom.relevancehub.net/?showWelcomeMsgPreviewID=wmid2
	//		* https://mediacom.relevancehub.net/article/407 => https://mediacom.relevancehub.net/article/407/?showWelcomeMsgPreviewID=wmid2
	//		* https://mediacom.relevancehub.net?test=abc	=> https://mediacom.relevancehub.net?test=abc&showWelcomeMsgPreviewID=wmid2
	//		* /												=> /?showWelcomeMsgPreviewID=wmid2
	//		* /?test=abc									=> /?test=abc&showWelcomeMsgPreviewID=wmid2
	//		* /article/407									=> /article/407?showWelcomeMsgPreviewID=wmid2
	//		* /article/407?test=abc							=> /article/407?test=abc&showWelcomeMsgPreviewID=wmid2
	//
	static getTargetURL( url, isPreviewMode )
	{
		let target_url = url
		if ( isPreviewMode && typeof(url)!=='undefined' && url!==null && url.trim().length>0 )
		{
			// Wenn Preview-Mode aktiv, dann übernehme alle Parameter aus der URL in die Preview-URL
			const TMP_DOMAIN = 'https://tmp.domain'
			const tmp_url = url.trim()
			const isAbsoluteUrl = /^\w*http.*/im.test(tmp_url)
			const url_url = new URL( isAbsoluteUrl
				? tmp_url
				: `${TMP_DOMAIN}${/^\/.*/im.test(tmp_url) ? '':'/'}${tmp_url}`
			)
			const isLocalUrl = ( window.location.host === url_url.host )
			if ( isLocalUrl || isAbsoluteUrl===false )
			{
				target_url = ( url_url.search.trim().length>0 
					? `${url_url.href}&${window.location.search.substring(1)}`
					: `${url_url.href}${window.location.search}`
				)
				if ( !isAbsoluteUrl && target_url.startsWith(TMP_DOMAIN) )		// is relative/local url ???
				{
					target_url = target_url.substring(TMP_DOMAIN.length)
				}
			}
		}
		// console.log(target_url)
		return target_url
	}
}

export default TargetURL