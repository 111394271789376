const Customer = (data) => {

	let customer = null
	// console.log(data)

	//#################################################################################
	const set = (d) => {
		// console.log(d, d)
		if (d===null || typeof(d) === 'undefined') 
		{
			return null
		}
		customer = d
		return customer
	}

	//#################################################################################
	//#################################################################################
	//#################################################################################
	if (typeof (data) !== 'undefined' && data !== null) 
	{
		set(data)
	}
	return customer
}

export default Customer