import * as React from 'react'
import { useEffect } from "react"
// import { useRef } from "react"
// import { useSelector } from 'react-redux'

import './TwitterTrendExplorer.scss'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'

import PaddingTop from "../../components/PaddingTop/PaddingTop"

import cmsConnect from "../../helpers/cmsConnect"
import help_module_tags from '../../placeholder/help_module_tags'
import TwitterTrendsConfig from '../../helpers/config_twitterTrends'
import HelpModuleAccordion from '../HelpModuleAccordion/HelpModuleAccordion'

const approx = require('approximate-number')

export default function TwitterTrendExplorer() 
{
	const geo_menu = [
		{value: TwitterTrendsConfig.places.worldwide.woeid, label: "Worldwide"},
		{value: TwitterTrendsConfig.places.germany.woeid, label: "Germany"},
		{value: TwitterTrendsConfig.places.united_states.woeid, label: "United States"},
		{value: TwitterTrendsConfig.places.united_kingdom.woeid, label: "United Kingdom"},
		{value: TwitterTrendsConfig.places.france.woeid, label: "France"},
	]
	const [geo, setGeo] = React.useState(geo_menu[1].value)
	const [trends, setTrends] = React.useState({
		hasData: false,
		error: null,		// string || null
		trends: null,		// Array of {name := string, promoted_content := null, query := string, tweet_volume := number, url := string}
		as_of: null,		// string := Timestamp
		created_at: null,	// string := Timestamp
		locations: null,	// Array of {name := string, woeid := number}
	})


	//#################################################################################
	useEffect(
		() => {
			async function getData() {
				await cmsConnect().POST_queue(
					'/api/eapi/twittertrends/find', 
					{ 
						place_woeid: geo
					},
					(res) => {
						let data_obj = {}
						if ( res.error == null )
						{
							// console.log("++ SUCCESS", res.data)
							data_obj = {...res.data, hasData: true, error: null}
						}
						else
						{
							console.error("Error TwitterTrendsExplorer():", res.error.data.error)
							data_obj = {hasData: false, error: res.error.data.error}
						}
						setTrends( (_) => data_obj )
					}
				)
			}
			getData()
		},
		[geo]
	)

	//#################################################################################
	const onFormSubmit = e => {

		e.preventDefault()
	}

	//#################################################################################
	const renderTrends = () => {
		let result = []
		
		if ( trends.hasData )
		{
			result.push(
				<div key={result.length} className='trends-container'>
					{trends.trends.map((e, i) => {
						return (
							<a key={i} href={e.url} target='_blank'>
								<div className='trend'>
									<div className='rank'>{i+1}.</div>
									<div className='data'>
										<div className='name'>{e.name}</div>
										{ e.tweet_volume 
											? <div className='volume'>{`${approx(e.tweet_volume, { capital: true })} Tweets`}</div>
											: null
										}
									</div>
								</div>
							</a>
						)
					})}
				</div>
			)
		}
		else // if ( trends.hasData == false )
		{
			result.push( trends.error 
				? <div key={result.length} className='loading-msg'>{trends.error}</div>
				: <div key={result.length} className='loading-msg'>No trends available.</div>
			)
		}

		return result
	}

	//#################################################################################
	//#################################################################################
	//#################################################################################
	return (<>
		<PaddingTop />
		<HelpModuleAccordion feature_tag={help_module_tags.TAG___TWITTER_TRENDS} />
		<div className='TwitterTrendExplorer'>
			<h2 className="h2_title">Twitter Trends</h2>
			<form onSubmit={onFormSubmit}>
				<div className="search-container">
					<div className="search-group">
						<FormControl variant="standard">
							<InputLabel>Geo</InputLabel>
							<Select
								value={geo}
								onChange={(event) => setGeo(event.target.value)}
								label="Geo"
							>
								{geo_menu.map((e, i) => <MenuItem key={i} value={e.value}>{e.label}</MenuItem>)}
							</Select>
						</FormControl>
					</div>
					<Button style={{display: "none"}} className='submit' type='submit' variant="contained">Search</Button>
					{/* <div className="search-group">
						<TextField inputRef={searchRef} className='mr20' label="Search" variant="standard" onBlur={() => onBlurField()} />
					</div> */}
				</div>
			</form>

			<div className="content-container">
				<h2>{`${geo_menu.find((e)=>e.value === geo).label}`} Twitter Trends</h2>
				{ renderTrends() }
			</div>
		</div>
	</>)
}