
import { Buffer } from "buffer"

//#################################################################################
class JSONEnDecoder
{
	// constructor() {}

	//=============================================================================
	static encodeJSON(json, encodeUri = true)
	{
		// let encoded = atob(JSON.stringify(json))	... deprecated
		let encoded = Buffer.from(JSON.stringify(json)).toString('base64')
		const len = Math.floor( encoded.length / 3 )
		if ( encoded.length > 3 )
		{
			const a = encoded.substring(0, len)
			const b = encoded.substring(len, len+len)
			const c = encoded.substring(len+len)
			encoded = b + c + a
		}
		// console.log(">>>> encodeJSON", len, encoded)
		return ( encodeUri 
			? encodeURIComponent(encoded)
			: encoded
		)
	}

	//=============================================================================
	static decodeJSON(encoded_data, decodeUri = true)
	{
		let data = ( decodeUri
			? decodeURIComponent(encoded_data)
			: encoded_data
		)
		const len = Math.floor( data.length / 3 )
		if ( data.length > 3 )
		{
			const b = data.substring(0, len)
			const c = data.substring(len, data.length-len)
			const a = data.substring(data.length-len)
			data = a + b + c
		}
		// console.log(">>>>> decodeJSON", len, data)
		try 
		{
			// const decoded = atob(data) ... deprecated
			const decoded = Buffer.from(data, 'base64').toString('ascii')
			return JSON.parse(decoded)
		}
		catch (e)
		{}
		return null
	}
}

export default JSONEnDecoder
