
import PlaceholderImg from "../assets/img/placeholder-image.png"
import _ from "lodash"


//#################################################################################
class ImageUrl
{
	static API_URL = process.env.REACT_APP_API_URL

	// constructor() {}

	//=============================================================================
	static get1x1(article)	// 360x360 (720x720)
	{
		const hero_11_obj = article.hero_11 || {}
		const hero_fallback_obj = article.hero || {}
		let url = (hero_11_obj.url || '').trim()
		if ( url.length <= 0 )
		{
			url = (hero_fallback_obj.url || '').trim()
		}
		if ( url.length > 0 )
		{
			return this.API_URL + url
		}
		return PlaceholderImg
	}

	//=============================================================================
	static get3x2(article)	// 750x564
	{
		const hero_32_obj = article.hero_32 || {}
		const hero_fallback_obj = article.hero || {}
		// const url = this.getImageFormat(hero_32_obj, hero_fallback_obj)
		const url = this.getOrigImageFormat(hero_32_obj, hero_fallback_obj)
		if ( url.length > 0 )
		{
			return this.API_URL + url
		}
		return PlaceholderImg
	}

	//=============================================================================
	static getHelpPage_3x2(helpPage)	// 750x564
	{
		const hero_32_obj = _.get(helpPage, "hero_32",  {})
		const hero_fallback_obj = {}
		const url = this.getOrigImageFormat(hero_32_obj, hero_fallback_obj)
		if ( url.length > 0 )
		{
			return this.API_URL + url
		}
		return PlaceholderImg
	}

	//=============================================================================
	static get16x9(article)	// 1140x640
	{
		const hero_169_obj = article.hero_169 || {}
		const hero_fallback_obj = article.hero || {}
		// const url = this.getImageFormat(hero_169_obj, hero_fallback_obj)
		const url = this.getOrigImageFormat(hero_169_obj, hero_fallback_obj)
		if ( url.length > 0 )
		{
			return this.API_URL + url
		}
		return PlaceholderImg
	}

	//=============================================================================
	static getImage(media_obj, format = 'large')
	{
		if ( typeof(media_obj)!=='undefined' && media_obj!==null )
		{
			const url = this.getImageFormat(media_obj, null, format)
			if ( url.length > 0 )
			{
				return this.API_URL + url
			}
		}
		return PlaceholderImg
	}

	//=============================================================================
	static getImageFormat(media_obj, fallback_media_obj = null, img_format = 'large')
	{
		//--------------------------------------- image
		let img_url = this._getFormat(media_obj, img_format)
		// console.log("A.1")
		if ( img_url.length > 0 ) return img_url
		img_url = (media_obj.url || '').trim()
		// console.log("A.2")
		if ( img_url.length > 0 ) return img_url
		
		//--------------------------------------- fallback
		img_url = this._getFormat(fallback_media_obj, img_format)
		// console.log("B.1")
		if ( img_url.length > 0 ) return img_url
		// console.log("B.2")
		return (fallback_media_obj.url || '').trim()
	}

	//=============================================================================
	static getOrigImageFormat(media_obj, fallback_media_obj = null)
	{
		//--------------------------------------- image
		let img_url = (media_obj.url || '').trim()
		if ( img_url.length > 0 ) return img_url
		
		//--------------------------------------- fallback
		return (fallback_media_obj.url || '').trim()
	}

	//=============================================================================
	static _getFormat(media_obj, format = 'large')
	{
		const hFormats_obj = media_obj.formats || {}
		const hLarge_obj = hFormats_obj[format] || {}
		return ( hLarge_obj.url || '').trim()
	}
}

export default ImageUrl