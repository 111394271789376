import React from "react";

const IconCalArrowRight = ({
//   style = {},
  fill = "#000",
  width = "19px",
  height = "19px",
  className = "",
  viewBox="0 0 19 19"
}) => (
	<svg 
		className={`svg-icon ${className || ""}`}
		x = "0"
		y = "0"
		width={width}
		height={height}
		viewBox={viewBox}
	>
		<path 
			d="M11.24,9.5l7.4-7.4c0.48-0.48,0.48-1.26,0-1.74c-0.48-0.48-1.26-0.48-1.74,0l-7.4,7.4l-7.4-7.4 c-0.48-0.48-1.26-0.48-1.74,0c-0.48,0.48-0.48,1.26,0,1.74l7.4,7.4l-7.4,7.4c-0.48,0.48-0.48,1.26,0,1.74 C0.6,18.88,0.92,19,1.23,19c0.31,0,0.63-0.12,0.87-0.36l7.4-7.4l7.4,7.4c0.24,0.24,0.56,0.36,0.87,0.36 c0.31,0,0.63-0.12,0.87-0.36c0.48-0.48,0.48-1.26,0-1.74L11.24,9.5z M11.24,9.5"
			fill={fill}
		/>
	</svg>
);

/*
<svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M15 18.5l-5-5.7 1.4-1.3 3.6 4.1 3.6-4.1 1.4 1.3-5 5.7z"
    />
  </svg>
  */

export default IconCalArrowRight;
