import { configureStore } from '@reduxjs/toolkit'
import userReducer from './functions/user_slice'
import customerReducer from './functions/customer_slice'
import pageReducer from './functions/page_slice'

export default configureStore({
	reducer: {
		user: userReducer,
		customer: customerReducer,
		page: pageReducer,
	},
})
