import User from "../models/User"
import Customer from "../models/Customer"
import dayjs from "dayjs"

const localData = () => {
	const tokenKey_lastVisit = `lastVisit-${window.location.host}`
	const tokenKey_autoLogout = `autoLogout-${window.location.host}`
	const tokenKey_user = `user-${window.location.host}`
	const tokenKey_customer = `customer-${window.location.host}`
	const tokenKey_page = `page-${window.location.host}`

	//#################################################################################
	const removeUser = () => {
		localStorage.removeItem(tokenKey_user)
		return true
	}

	//#########################################
	const getUser = () => {
		const storedItem = localStorage.getItem(tokenKey_user)
		const data = typeof (storedItem) === 'undefined' || storedItem === '' ? User() : JSON.parse(storedItem)
		const toReturn = User(data)
		return toReturn
	}

	//#########################################
	const setUser = (d) => {
		const toSet = User(d)
		if (toSet === null) 
		{
			return
		}
		localStorage.setItem(tokenKey_user, JSON.stringify(toSet))
	}

	//#################################################################################
	const removeCustomer = () => {
		removeCustomerColors()
		localStorage.removeItem(tokenKey_customer)
		return true
	}

	//#########################################
	const getCustomer = () => {
		const storedItem = localStorage.getItem(tokenKey_customer)
		const data = typeof (storedItem) === 'undefined' || storedItem === '' ? null : JSON.parse(storedItem)
		const toReturn = Customer(data)
		return toReturn
	}

	//#########################################
	const setCustomer = (d) => {
		const toSet = Customer(d)
		if (toSet === null) 
		{
			return
		}
		localStorage.setItem(tokenKey_customer, JSON.stringify(toSet))
		updateCustomerColors()
	}

	//#########################################
	const updateCustomerColors = () => {
		const customer = getCustomer()
		if ( customer != null )
		{
			for( const [key, value] of Object.entries(customer.colors.pre) )
			{
				// console.log(`--${key}: ${value}`)
				document.body.style.setProperty(`--${key}`, value)
			}
			for( const [key, value] of Object.entries(customer.colors.post) )
			{
				// console.log(`--${key}: ${value}`)
				document.body.style.setProperty(`--${key}`, value)
			}
		}
	}

	//#########################################
	const removeCustomerColors = () => {
		const customer = getCustomer()
		if ( customer != null )
		{
			for( const [key, /*value*/] of Object.entries(customer.colors) )
			{
				document.body.style.setProperty(`--${key}`, 'inherit')
			}
		}
	}

	//#################################################################################
	/*
	const getLastVisit = () => {
		let lastVisit = localStorage.getItem(tokenKey_lastVisit)
		return ( typeof(lastVisit)==='undefined' || lastVisit===null 
			? new Date().getTime()
			: lastVisit
		)
	}
	*/

	//#########################################
	/*
	const setLastVisit = (d) => {
		if ( typeof(d)==='undefined' || d===null ) return
		localStorage.setItem(tokenKey_lastVisit, d)
	}
	*/

	//#########################################
	/*
	const validateLastVisit = () => {
		const logout_hours = process.env.REACT_APP_LOGOUT_AFTER_HOURS
		if ( 
			getUser()===null 
			|| ( typeof(logout_hours)==='undefined' || logout_hours===null || Number.isInteger(Number(logout_hours))<=0 )
		)
		{ 
			return		//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
		}
		console.log(`++ auto. logout after ${Number(logout_hours)} h`)
		const lastVisit = getLastVisit()
		const currentTimestamp = new Date().getTime()
		const diff = currentTimestamp - lastVisit
		if ( diff<0 || diff>(Number(logout_hours)*60*60*1000) )
		{
			removeUser()
		}
		setLastVisit(currentTimestamp)
	}
	*/

	//#########################################
	const getAutoLogout = () => {
		let value = localStorage.getItem(tokenKey_autoLogout)
		return ( typeof(value)==='undefined' || value===null 
			? null
			: value
		)
	}

	//#########################################
	const setAutoLogout = (d) => {
		if ( typeof(d)==='undefined' || d===null ) d = dayjs().millisecond(0).format('YYYY-MM-DD HH:mm:ss')
		localStorage.setItem(tokenKey_autoLogout, d)
	}

	//#########################################
	const clearAutoLogout = () => {
		localStorage.removeItem(tokenKey_autoLogout)
	}

	//#################################################################################
	// const removePage = () => {
	// 	localStorage.removeItem(tokenKey_page)
	// 	return true
	// }

	//#########################################
	const getPage = () => {
		const storedItem = localStorage.getItem(tokenKey_page)
		const data = typeof (storedItem) === 'undefined' || storedItem === '' ? {} : JSON.parse(storedItem)
		// const toReturn = User(data)
		const toReturn = data
		return toReturn
	}

	//#########################################
	const setPage = (d) => {
		// const toSet = User(d)
		const toSet = d
		if (toSet === null) 
		{
			return
		}
		localStorage.setItem(tokenKey_page, JSON.stringify(toSet))
	}

	//#################################################################################
	//#################################################################################
	//#################################################################################
	return {
		getUser,
		setUser,
		removeUser,

		getCustomer,
		setCustomer,
		removeCustomer,
		updateCustomerColors,

		// validateLastVisit, ... wurde durch Auto-Logout ersetzt, weil neues Login mit Refresh-Token
		getAutoLogout,
		setAutoLogout,
		clearAutoLogout,

		// getPage+setPage habe ich angelegt für helpers/tracking_utils, um Tracken zu können, wenn der User die Page verlässt.
		// Es gibt zwei Events pageVisibility (helpers/tracking_utils/pageVisibility.js) und window.onBeforeUnload.
		// Leider funktioniert keine der Varianten auf allen Browsern.
		// - pageVisibility funktioniert nur unter Chrome, wie ich es mir vorstelle (Event, wenn man den Tab wechselt und auch wenn man die Page schließt).
		//	 Bei den anderen Browsers wird der Event nur dann aktiviert, wenn man den Tab im Browser wechselt, nicht aber wenn man z.B. den Tab schließt
		// - window.onBeforeUnload funktioniert leider auch nicht in allen Browsern. 
		//   Dieser Event wird zwar aktiv, wenn man die Page schließt, aber in einigen Browsern wird immer ein Popup angezeigt und dieser kann man nicht in allen Browsers unterdrücken
		getPage,
		setPage,
	}
}

export default localData;