
//#################################################################################
class NoScrolling
{

	//#################################################################################
	static disable_scrolling = () => {
		const elem = document.querySelector('#root')
		// if ( !elem.classList.contains('no-scroll') )	..... wenn man diese Funktion in mehreren Views gleichzeitig verwendet, dann löscht diese Funkt. den Flag für alle geöffneten Views
		// {
			elem.classList.add('no-scroll')
		// }
	}

	//#################################################################################
	static enable_scrolling = () => {
		const elem = document.querySelector('#root')
		if ( elem.classList.contains('no-scroll') )
		{
			elem.classList.remove('no-scroll')
		}
	}
}

export default NoScrolling