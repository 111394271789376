import { createSlice } from '@reduxjs/toolkit'
import localData from '../../helpers/localData'

export const customerSlice = createSlice({
	name: 'customer',
	
	initialState: {
		value: localData().getCustomer(),
	},

	reducers: {
		//#################################################################################
		setCustomer: (state, action) => {
			// console.log('-- setCustomer :: action', action)
			localData().setCustomer( action.payload )
			state.value = action.payload
		},

		//#################################################################################
		clearCustomer: (state) => {
			state.value = null
			localData().removeCustomer()
		},
	},
})

// Action creators are generated for each case reducer function
export const { setCustomer, clearCustomer } = customerSlice.actions

export default customerSlice.reducer