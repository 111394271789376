
import dayjs from 'dayjs'
// import localizedFormat from 'dayjs/plugin/localizedFormat'
import CalendarDate from './CalendarDate'

// dayjs.extend(localizedFormat)

const dateFormat = () => {

	// const mon_short = ['JAN', 'FEB', 'MÄRZ', 'APR', 'MAI', 'JUN', 'JUL', 'AUG', 'SEP', 'OKT', 'NOV', 'DEZ']
	const mon_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	const mon_long = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	const weekday_long = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

	//#################################################################################
	const article_shortMonth = (date) => {
		const d = new Date(date)
		
		return mon_short[d.getMonth()]
	}

	//#################################################################################
	const articlePreview_shortDate = (date) => {
		const d = dayjs(date)
		return `${d.format('DD')} ${mon_short[d.month()]} ${d.format('YYYY')}`
	}

	//#################################################################################
	const articlePreview_weekdayLong_monthInWords_noTime = (date) => {
		const d = dayjs(date)
		return `${weekday_long[d.day()]}, ${d.format('D')}. ${mon_long[d.month()]} ${d.format('YYYY')}`
	}

	//#################################################################################
	const calendar_weekdayLong_monthInWords_dateOnly_noTime = (date) => {
		const d = dayjs(date)
		return `${weekday_long[d.day()]}, ${mon_long[d.month()]} ${d.format('D')}`
	}

	//#################################################################################
	// time_zone : {String} ("America/New_York", "Europe/Berlin", ...)
	//
	const calendar_formatDatePeriod = (start_date, end_date, date_without_time /*, time_zone = 'Europe/Berlin'*/) => {

		if ( typeof(start_date)==='undefined' || start_date===null || typeof(end_date)==='undefined' || end_date===null )
		{
			return '-'
		}

		//-----------------------------------------------------------------------------
		//-----------------------------------------------------------------------------
		//-----------------------------------------------------------------------------
		// ACHTUNG!! dayjs(start_date) erzeugt ein Datum für die TZ, die auf dem aktuellen Client eingestellt ist.
		// D.h. dayjs('2022-09-29 16:00-07:00') => '2022-09-30 01:00' für '+02:00'
		//-----------------------------------------------------------------------------
		//-----------------------------------------------------------------------------
		//-----------------------------------------------------------------------------
		const tsFrom = dayjs(start_date)		// convert DB time stamp to local/client TZ
		const tsTo	 = dayjs(end_date)			// convert DB time stamp to local/client TZ

		const chkFromDay = dayjs( tsFrom.format('YYYY-MM-DD') )		// nur das Datum extrahieren; Uhrzeit ist 00:00:00.000
		const chkToDay	 = dayjs( tsTo.format('YYYY-MM-DD') )		// nur das Datum extrahieren; Uhrzeit ist 00:00:00.000

		if ( chkFromDay.isSame(chkToDay) )
		{
			return ( date_without_time
				? `${mon_short[tsFrom.month()]} ${tsFrom.date()}`
				: `${mon_short[tsFrom.month()]} ${tsFrom.date()}, ${tsFrom.format('HH:mm')} - ${tsTo.format('HH:mm')}`
			)
		}

		return ( date_without_time
			? `${mon_short[tsFrom.month()]} ${tsFrom.date()} - ${mon_short[tsTo.month()]} ${tsTo.date()}`
			: `${mon_short[tsFrom.month()]} ${tsFrom.date()}, ${tsFrom.format('HH:mm')} - ${mon_short[tsTo.month()]} ${tsTo.date()}, ${tsTo.format('HH:mm')}`
		)
	}

	//#################################################################################
	//#################################################################################
	//#################################################################################
	return {
		article_shortMonth,
		articlePreview_shortDate,
		articlePreview_weekdayLong_monthInWords_noTime,
		calendar_weekdayLong_monthInWords_dateOnly_noTime,
		calendar_formatDatePeriod
	}
}

export default dateFormat