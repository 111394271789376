import { useEffect, useState /*, useRef*/ } from "react"
// import { useParams /*, useNavigate*/ } from "react-router-dom"
import PropTypes from 'prop-types'
import classnames from "classnames"

import './EventDetail.scss'
import IconCalClose from "../../assets/icons/IconCalClose"
import IconCalAdd2Cal from "../../assets/icons/IconCalAdd2Cal"

import ScrollOut from "scroll-out"
//
import dayjs from 'dayjs'
// import advanced from 'dayjs/plugin/advancedFormat'		// für dayjs().format('z') ... Buchstabe 'z'
//
import { atcb_init, atcb_action } from "add-to-calendar-button";
import 'add-to-calendar-button/assets/css/atcb.css'

import { Tooltip } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import VideoPlayer from "../VideoPlayer/VideoPlayer"
import { TikTok } from "react-tiktok"
import { InstagramEmbed, TwitterEmbed } from 'react-social-media-embed'

// tools
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'		// adds support for strikethrough, tables, tasklists and URLs directly
import remarkGfm from 'remark-gfm'		// react-markdown typically escapes HTML (or ignores it, with skipHtml) because it is dangerous and defeats the purpose of this library. However, if you are in a trusted environment (you trust the markdown), and can spare the bundle size (±60kb minzipped), then you can use 'rehype-raw'
//
// import HtmlHeader from '../HtmlHeader/HtmlHeader'
// import cmsConnect from "../../helpers/cmsConnect"
import dateFormat from "../../helpers/dateFormat"
import StrapiImage from "../../helpers/StrapiImage"
import TargetURL from "../../helpers/TargetURL"
import Event2Cal from "../../helpers/Event2Cal"
import routes from "../../routes/routes"
// import JSONEnDecoder from "../../helpers/JSONEnDecoder"
// import CalendarDate from "../../helpers/CalendarDate"

let returnDate = dayjs()
let returnEncodedDataBody = ''

export default function EventDetail({event, isPreview}) 
{
	const format_date = dateFormat()

	//#################################################################################
	useEffect(atcb_init, [])

	useEffect(
		() => {
			//-------------------------------------------------------------------------
			// https://oxygen4fun.supadezign.com/tutorials/scroll-animations-with-scrollout/
			//
			// const so = ScrollOutUtils.init()
			const so = ScrollOut({
				// threshold: 0.2,		//0.5
				once: true,
				// cssProps: {
				// 	viewportX: true,		// die Variable --viewport-x in CSS exportieren (Zugriff mit var(--viewport-x), wenn data-scroll in dem entsprechenden Tag angegeben wurde)
				// 	// viewportY: true,		// die Variable --viewport-y in CSS exportieren
				// 	// offsetX: true,		// die Variable --offset-x in CSS exportieren
				// },
			})

			//-------------------------------------------------------------------------
			if ( document.location.hash.length > 0 )
			{
				const PARAM = 'cal-date'
				const query = document.location.hash.substring(1)
				if ( query.startsWith(PARAM) )
				{
					let date_str = query.substring(PARAM.length)
					if ( /\d{8}(|.+)*/i.test(date_str) )
					{
						returnDate = dayjs(date_str.substring(0,8), 'YYYYMMDD')		// save return date

						let data_encoded = date_str.substring(8)
						if ( data_encoded.startsWith('|') )
						{
							returnEncodedDataBody = data_encoded					// save return (encoded) data body
						}
					}
				}
			}

			//=========================================================================
			return () => {
				so.teardown()		// teardown ScrollOut
			}
		}
	)

	// useEffect(
	// 	() => {},
	// 	[]
	// )

	//#################################################################################
	const getCustomImgWidth = (data) => {
		let img_width = (data.image_width || '').trim().toLowerCase()
		switch ( img_width )
		{
			case 'w60%': return 'w60'
			case 'w80%': return 'w80'
			default:
				// if ( img_width.startsWith('w100%') ) return 'w100' ........ return '' reicht völlig
				return ''
		}
		return ''
	}

	//#################################################################################
	const renderEvent = () => {
		const header_d_img = StrapiImage.getImage( event.hero_3x2 )
		const header_m_img = StrapiImage.getImage( event.hero_1x1 )
		const period = format_date.calendar_formatDatePeriod(event.date_from, event.date_to, event.date_without_time)
		return (<>
			<div className="nav-container sticky-header">
				{ typeof(isPreview)==='undefined' || isPreview===null || (typeof(isPreview)==='boolean' && isPreview===false)
					?	(
							<Tooltip arrow title="Close">
								<div className="close-icon" onClick={() => onClose_window()}>
									<IconCalClose />
								</div>
							</Tooltip>
						)
					:	null
				}
			</div>
			<div className="root-container">
				{/* ---------------------------------------------------- header */}
				<div className="hero-container" data-scroll>
					<div className='img-container'>
						<CardMedia
							component="img"
							className="hero_d"
							image={header_d_img}
							alt={event.title}
						/>
						<CardMedia
							component="img"
							className="hero_m"
							image={header_m_img}
							alt={event.title}
						/>
						<div className="current-date">
							<span className="month">{format_date.article_shortMonth(returnDate)}</span>
							<span className="date">{returnDate.date()}</span>
						</div>
					</div>
					<div className="title-container-m">
						<div className="title" dangerouslySetInnerHTML={{__html: event.title}} />
						<div className="subtitle" dangerouslySetInnerHTML={{__html: period}} />
					</div>
					<div className="title-container-d">
						<div className="title" dangerouslySetInnerHTML={{__html: event.title}} />
						<div className="subtitle" dangerouslySetInnerHTML={{__html: period}} />
					</div>
				</div>
				<div className="article-content">
					{/* ------------------------------------------------ content container */}
					<div className="content-container">
						{event.content.map((e, i) => {
							switch ( e.__component )
							{
								case "content-modules.main-title":
									return ( 
										<h1
											key={i} 
											dangerouslySetInnerHTML={{__html: e.title}} 
											data-scroll
										/>
									)

								case "content-modules.title": 
									return ( 
										<h2 
											key={i} 
											className="title"
											dangerouslySetInnerHTML={{__html: e.title}} 
											data-scroll
										/>
									)

								case "content-modules.paragraph-1col":
									return ( 
										<div key={i} className="p1col" data-scroll>
											<ReactMarkdown 
												linkTarget="_blank"
												rehypePlugins={[remarkGfm, rehypeRaw]} 
												children={e.text} 
											/>
										</div>
									)

								case 'content-modules.event-cta':
									return (
										<div key={i} className='cta-container' data-scroll>
											<Button 
												// type="submit"
												className={classnames('button-root')}
												variant='contained'
												// startIcon={formData.serverRequestActive ? <CircularProgress /> : <Fingerprint />}
												disabled={false}
												onClick={() => {
													if ( /^http(s*):\/\/.*/im.test( e.url ) )
													{
														window.open( TargetURL.getTargetURL(e.url, false), '_blank')
													}
													else
													{
														window.location.href = TargetURL.getTargetURL(e.url, false)
													}
												}}
												disableElevation
												disableRipple
											>
												{e.name}
											</Button>
										</div>
									)
								
								case 'content-modules.event-add-2-cal-cta':
									return (
										<div key={i} className='cta-container' data-scroll>
											<Button 
												// type="submit"
												className={classnames('button-root', 'cal-button')}
												variant='contained'
												startIcon={<IconCalAdd2Cal className='cal-icon' />}
												disabled={false}
												onClick={(ctaEvent) => {
													const config = Event2Cal.getCalEntry( event )
													// console.log(">>>>>> event-add-2-cal-cta : config", config)
													atcb_action(config, ctaEvent.target)
												}}
												disableElevation
												disableRipple
											>
												{e.name}
											</Button>
										</div>
									)
								
								case "content-modules.internal-image":
									{
										// const src = ( e.image 
										// 	? (API_URL + e.image.url)
										// 	: PlacehodelImg
										// )
										const src = StrapiImage.getImage(e.image, 'large')
										const description = e.description || ''
										return (
											<div key={i} className={classnames("img-container", getCustomImgWidth(e))} data-scroll>
												<div className="img-shadow" />
												<CardMedia
													key={i}
													component="img"
													className="internal-image"
													image={src}
													alt={e.alt_text || 'image'}
												/>
												{ description.trim().length > 0
													? <div className="description" dangerouslySetInnerHTML={{__html: description}} />
													: null
												}
											</div>
										)
									}
								
								case "content-modules.you-tube-video":
									return (
										<VideoPlayer key={i} article={e} />
									)
								
								case "content-modules.tik-tok-video":
									{
										const url = new URL(e.video_url)
										const target_url = `${url.origin}${url.pathname}`
										return (
											<div key={'tiktok_vid_'+i} className={classnames("tiktok-container", getCustomImgWidth(e))} data-scroll>
												<TikTok url={target_url} />
											</div>
										)
									}

								case "content-modules.instagram-post":
									{
										const url = new URL(e.instagram_url)
										const target_url = `${url.origin}${url.pathname}`
										return (
											<div key={'instagram_media_'+i} className={classnames("media-container", getCustomImgWidth(e))} data-scroll>
												<InstagramEmbed url={target_url} width="100%" />
											</div>
										)
									}

								case "content-modules.twitter-post":
									{
										const url = new URL(e.twitter_url)
										const target_url = `${url.origin}${url.pathname}`
										return (
											<div key={'twitter_media_'+i} className={classnames("media-container", getCustomImgWidth(e))} data-scroll>
												<TwitterEmbed url={target_url} width="100%" />
											</div>
										)
									}

								default:
									console.error(`Error: Unknown component '${e.__component}'`, e)
									break
							}
							return null
						})}
					</div>
				</div>
			</div>
		</>)
	}

	//#################################################################################
	const onClose_window = () => {
		
		window.location.href = routes.calendar + `#date${returnDate.format('YYYYMMDD')}${returnEncodedDataBody}`
	}
	
	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	const isLoading = ( Object.keys(event).length <= 0 )
	//
	return (<>
		<div className={classnames('ArticleDetail', 'EventDetail')}>
			<div className={classnames('wrapper')}>
				{ isLoading
					? <span className="loading-msg">Loading ...</span>
					: renderEvent()
				}
			</div>
		</div>
	</>)
}

EventDetail.propTypes = {
	event: PropTypes.object,
	// fetchInProgress: PropTypes.bool,
	// cb_onShowMore: PropTypes.func,
}
  
EventDetail.defaultProps = {
	event: {},
	// fetchInProgress: false,
	// cb_onShowMore: null,
}