import axios from "axios"
import localData from './localData'
import routes from '../routes/routes'
import Semaphore from "./Semaphore"

const _ = require('lodash')

let httpRequestQueue = new Semaphore(1)				// persistent!

const cmsConnect = () => {
	// const dispatch = useDispatch()
	const path = process.env.REACT_APP_API_URL

	//#################################################################################
	const refreshToken = async (error, retryRequest) => {
		const response = error.response
		// console.log(`----- cmsConnection.refreshToken :: response=`, response)
		if ( response.status === 401 )
		{
			let user = localData().getUser()
			const jwt = user!==null && typeof(user)!=='undefined' && typeof(user.jwt)!=='undefined' ? user.jwt : ''
			if ( jwt.length > 0 )
			{
				let err = false
				try 
				{
					const execRefreshToken	= false			// false = do not exec. refresh-token, when POST:/api/auth/refresh-token returns status 401 !!!
					const sendBearer		= false			// false = do not send bearer, when executing POST:/api/auth/refresh-token !!!
					const res = await POST('/api/auth/refresh-token', {'authToken': jwt}, execRefreshToken, sendBearer)
					// console.log(`----- cmsConnection :: refresh-token :: res=`, res)
					// console.log(">>>>>>> res.data.jwt", res.data.jwt)
					const status_code	= _.get( res, 'error.status', null )
					const new_jwt		= _.get( res, 'data.jwt', null )
					const expiryTS_rt	= _.get( res, 'data.expiryTS_rt', null )
					if ( (status_code!==null && parseInt(status_code)!==200) || new_jwt===null || expiryTS_rt===null )
					{
						return res
					}
					else
					{
						user.jwt = new_jwt		// user.jwt = res.data.jwt
						user.expiryTS_rt = expiryTS_rt
						localData().setUser( user )

						switch ( retryRequest.method )
						{
							case 'get': return await GET( retryRequest.url )
							case 'post': return await POST( retryRequest.url, retryRequest.data )
							case 'put': return await PUT( retryRequest.url, retryRequest.data )
							case 'delete': return await DELETE( retryRequest.url )
							default:
								err = true
								break
						}
					}
				}
				catch (e) 
				{
					throw e
				}
				if ( err )
				{
					console.error(`Unknown method '${retryRequest.method}'`, retryRequest)
					throw err
				}
			}
		}
	}

	//#################################################################################
	const getHeaders = (sendBearer) => {
		// console.log('-- getHeaders', localData().getUser())
		let user = localData().getUser()
		const jwt = user!==null && typeof(user)!=='undefined' && typeof(user.jwt)!=='undefined' ? user.jwt : ''

		var config = {
			withCredentials: true,		// needed to get/set cookies : https://flaviocopes.com/axios-credentials/
		}
		if ( sendBearer && jwt.length>0 )
		{
			Object.assign(config, { 
				headers: { Authorization: `Bearer ${jwt}` },
			})
		}
		return config
	}

	//#################################################################################
	const handleError = async (e, execRefreshToken, retryRequestOnError, query) => {
		if ( !e.response ) 
		{
			return e
		}
		// console.log('-- handleError :: response.data', e.response)
		// console.log('-- handleError :: response.data', e.response.data)
		// console.log('-- handleError :: response.status', e.response.status)
		// console.log('-- handleError :: response.headers', e.response.headers)

		if ( execRefreshToken === true )
		{
			const res = await refreshToken(e, retryRequestOnError)
			const status_code	= _.get( res, 'error.status', null )
			if ( status_code!==null && parseInt(status_code)!==200 )
			{
				routes.navToRoute( routes.login )
				// setTimeout(()=>{ console.log(">>>>>>>>>> refresh-token RESULT", res) }, 2000); const x = 0; x = 1
			}
			return res
		}

		let loc = window.location
		return {
			location: loc.pathname,
			query,
			error: (e.response || 'Unkown error!')
		}
	}

	//#################################################################################
	const GET = async (url, execRefreshToken = true, sendBearer = true) => {
		try 
		{
			const res = await axios.get(path + url, getHeaders(sendBearer))
			// console.log(res)
			return res
		}
		catch (e) 
		{
			return await handleError(
				e, 
				execRefreshToken, 
				{
					method: 'get',
					url: url
				}
			)
		}
	}
	const GET_queue = async (url, callback, execRefreshToken = true, sendBearer = true) => {
		httpRequestQueue.callFunction(async () => {
			try 
			{
				const res = await axios.get(path + url, getHeaders(sendBearer))
				// console.log(res)
				if ( typeof(callback)!=='undefined' && callback!==null ) callback( res )
			}
			catch (e) 
			{
				const res = await handleError(
					e, 
					execRefreshToken, 
					{
						method: 'get',
						url: url
					}
				)
				if ( typeof(callback)!=='undefined' && callback!==null ) callback( res )
			}
		})
	}

	//#################################################################################
	const POST = async(url, data, execRefreshToken = true, sendBearer = true) => {
		try 
		{
			const res = await axios.post((path + url), data, getHeaders(sendBearer))
			// console.log(res)
			return res
		}
		catch (e) 
		{
			return await handleError(
				e, 
				execRefreshToken, 
				{
					method: 'post',
					url: url,
					data: data,
				},
				data
			) 
		}
	}
	/**
	 * @param {*} callback 
	 * @param {*} url 
	 * @param {*} data 
	 * @param {*} execRefreshToken 
	 * @param {*} sendBearer 
	 * 
	 * @example
	 * https://platform.inca-global.com/api/v1/discovery/talents/4tiATEuY/export/csv?x-public-auth=
	 *  ..... das wait vor "cmsConnect().POST_queue(...bla" ist optional, wie es benötigt wird
	 *	await cmsConnect().POST_queue(
			'/api/auth/local', 
			{ identifier: 'xxxx@xxxxx.com', password: 'xxxxxxxxxxx' },
			(res) => {
				console.log("+++ test_login()", res.data)
				dispatch( logIn(User(res.data)) )
			}
		)
	 */
	const POST_queue = async(url, data, callback, execRefreshToken = true, sendBearer = true) => {
		httpRequestQueue.callFunction(async () => {
			try 
			{
				const res = await axios.post((path + url), data, getHeaders(sendBearer))
				// console.log(res)
				if ( typeof(callback)!=='undefined' && callback!==null ) callback( res )
			}
			catch (e) 
			{
				const res = await handleError(
					e, 
					execRefreshToken, 
					{
						method: 'post',
						url: url,
						data: data,
					},
					data
				) 
				if ( typeof(callback)!=='undefined' && callback!==null ) callback( res )
			}
		})
	}

	//#################################################################################
	const PUT = async (url, data = {}, execRefreshToken = true, sendBearer = true) => {
		try 
		{
			const res = await axios.put(path + url, data, getHeaders(sendBearer))
			// console.log(res)
			return res
		}
		catch (e) 
		{
			return await handleError(
				e, 
				execRefreshToken,
				{
					method: 'put',
					url: url,
					data: data,
				},
			)
		}
	}
	const PUT_queue = async (url, data = {}, callback, execRefreshToken = true, sendBearer = true) => {
		httpRequestQueue.callFunction(async () => {
			try 
			{
				const res = await axios.put(path + url, data, getHeaders(sendBearer))
				// console.log(res)
				if ( typeof(callback)!=='undefined' && callback!==null ) callback( res )
			}
			catch (e) 
			{
				const res = await handleError(
					e, 
					execRefreshToken,
					{
						method: 'put',
						url: url,
						data: data,
					},
				)
				if ( typeof(callback)!=='undefined' && callback!==null ) callback( res )
			}
		})
	}

	//#################################################################################
	const DELETE = async (url, execRefreshToken = true, sendBearer = true) => {
		try 
		{
			const res = await axios.delete(path + url, getHeaders(sendBearer))
			// console.log(res)
			return res
		}
		catch (e) 
		{
			return await handleError(
				e, 
				execRefreshToken,
				{
					method: 'put',
					url: url,
				},
			)
		}
	}
	const DELETE_queue = async (url, callback, execRefreshToken = true, sendBearer = true) => {
		httpRequestQueue.callFunction(async () => {
			try 
			{
				const res = await axios.delete(path + url, getHeaders(sendBearer))
				// console.log(res)
				if ( typeof(callback)!=='undefined' && callback!==null ) callback( res )
			}
			catch (e) 
			{
				const res = await handleError(
					e, 
					execRefreshToken,
					{
						method: 'put',
						url: url,
					},
				)
				if ( typeof(callback)!=='undefined' && callback!==null ) callback( res )
			}
		})
	}

	//#################################################################################
	//#################################################################################
	//#################################################################################
	return {
		path,
		httpRequestQueue,
		//----------
		GET,
		POST,
		PUT,
		DELETE,
		//----------
		GET_queue,
		POST_queue,
		PUT_queue,
		DELETE_queue,
	};
}

export default cmsConnect