
import * as React from 'react'
import { useEffect, useState } from "react"
// import { useParams, useNavigate } from "react-router-dom"
import PropTypes from 'prop-types'
import classnames from "classnames"

import dayjs from 'dayjs'

import './CalendarDatePicker.scss'
import IconCalArrowLeft from "../../assets/icons/IconCalArrowLeft"
import IconCalArrowRight from "../../assets/icons/IconCalArrowRight"
import IconCalArrowDown from "../../assets/icons/IconCalArrowDown"

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
// import deLocale from 'date-fns/locale/de'		// Zeigt: Mo, Di, Mi, Do, Fr, Sa, So
import enLocale from 'date-fns/locale/en-GB'		// Zeigt: Mo, Tu, We, Th, Fr, Sa, Su

import { PickersDay } from '@mui/x-date-pickers/PickersDay'


const DAYS_OF_WEEK = {
	'mo': 'Mon',
	'tu': 'Tue',
	'we': 'Wed',
	'th': 'Thu',
	'fr': 'Fri',
	'sa': 'Sat',
	'su': 'Sun',
}


export default function CalendarDatePicker({date, cb_onDateSelected, cb_onMonthYearChanged, minDate, maxDate, daysWithEvents})
{
	//#################################################################################
	// useEffect( () => 
	// 	{
	// 	}, 
	// 	[]
	// )

	//#################################################################################
	const renderWeekPickerDay = (date, selectedDays, pickersDayProps) => {

		// console.log("---", date, selectedDays, pickersDayProps)
		const yyyy = date.getFullYear()
		const mm = date.getMonth() + 1
		const dd = date.getDate()
		const dateKey = dayjs(date).format('YYYYMMDD')
		const hasEvent = ( typeof(daysWithEvents[dateKey])!=='undefined' && daysWithEvents[dateKey]!==null )
		return (
			<div key={`day_${yyyy}${mm}${dd}`} className={classnames('day-container', {'has-event': hasEvent})}>
				<PickersDay {...pickersDayProps} />
			</div>
		)
	}

	//#################################################################################
	const _onMonthYearChanged = (date) => {
		if ( typeof(cb_onMonthYearChanged)==='undefined' || cb_onMonthYearChanged===null )
		{
			console.error("Error (CalendarDatePicker): cb_onMonthYearChanged not specified!")
			return
		}
		cb_onMonthYearChanged( date )
	}

	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return (
		<div className='CalendarDatePicker'>
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
				<CalendarPicker
					date={date} 
					onChange={(newDate) => {
						// console.log(">>>>>> onChange", newDate, typeof(newDate))
						if ( typeof(cb_onDateSelected)==='undefined' || cb_onDateSelected===null )
						{
							console.error("Error (CalendarDatePicker): cb_onDateSelected not specified!")
							return
						}
						cb_onDateSelected( newDate )
					}}
					onMonthChange={(date) => {
						// console.log(">>>>>> onMonthChange", date, typeof(date))
						_onMonthYearChanged( date )
					}}
					// onYearChange={(date) => { ............. ist nicht nötig, weil wenn das Jahr geändert wird auch ein onChange ausgeführt wird
					// 	// console.log(">>>>>> onYearChange", date, typeof(date))
					// 	_onMonthYearChanged( date )
					// }}
					dayOfWeekFormatter={e => DAYS_OF_WEEK[e.toLowerCase()] }
					minDate={minDate}
					maxDate={maxDate}
					showDaysOutsideCurrentMonth={true}
					renderDay={renderWeekPickerDay}
					components={{
						LeftArrowIcon: IconCalArrowLeft,
						RightArrowIcon: IconCalArrowRight,
						SwitchViewIcon: IconCalArrowDown,
					}}
				/>
				<div className="bottom-line"></div>
			</LocalizationProvider>
		</div>
	)
}


CalendarDatePicker.propTypes = {
	// entries: PropTypes.object,
	// fetchInProgress: PropTypes.bool,
	date: PropTypes.object,
	cb_onDateSelected: PropTypes.func,
	cb_onMonthYearChanged: PropTypes.func,
	minDate: PropTypes.object,
	maxDate: PropTypes.object,
	daysWithEvents: PropTypes.object
}
  
CalendarDatePicker.defaultProps = {
	// entries: [],
	// fetchInProgress: false,
	date: new Date(),
	cb_onDateSelected: null,
	cb_onMonthYearChanged: null,
	minDate: null,
	maxDate: null,
	daysWithEvents: {}
}
