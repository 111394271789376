
import jwt_decode from "jwt-decode"
import mstudio_placeholder from "../placeholder/mstudio_placeholder"

const dayjs = require('dayjs')
const _ = require('lodash')

const User = (data) => {

	let user = null
	// console.log(data)

	//#################################################################################
	const set = (d) => {
		// console.log(d, d)
		if (
				typeof(d) === 'undefined' || d===null
			||	_.get(d, 'jwt', null) === null
			||	_.get(d, 'expiryTS_rt', null) === null
			||	_.get(d, 'user.email', null) === null
			||	_.get(d, 'user.username', null) === null 
			||	_.get(d, 'user.id', null) === null
			// ||	_.get(d, 'user.helpPageTitle', null) === null
		) 
		{
			return null
		}
		const lastLogin = ( _.get(d, 'lastLogin', null) === null
			? new Date().getTime() 
			: d.lastLogin
		)
		/*
		const decoded_jwt = jwt_decode( d.jwt )
		const exp = _.get(decoded_jwt, 'exp', null)
		let expiration_ts = null
		if ( exp !== null )
		{
			const d = new Date(0).setUTCSeconds( exp )
			expiration_ts = dayjs(d).format('YYYY-MM-DD HH:mm:ss')
		}
		*/
		const expiryTS_rt = d.expiryTS_rt
		user = {
			jwt: d.jwt,
			// expiration_ts,		// expiry timestamp from jwt
			expiryTS_rt,			// expiry timestamp from refresh token
			user: {
				id:				_.get(d, 'user.id', null),
				email:			_.get(d, 'user.email', null),
				username:		_.get(d, 'user.username', null),
				avatar:			_.get(d, 'user.avatar', null),
				role:			_.get(d, 'user.role', null),
				helpPageTitle:	_.get(d, 'user.helpPageTitle', mstudio_placeholder.DEFAULT_HELP_PAGE_TITLE)
			},
			lastLogin
		}
		return user
	}

	//#################################################################################
	//#################################################################################
	//#################################################################################
	if (typeof (data) !== 'undefined' && data !== null) 
	{
		set(data)
	}
	return user
}

export default User