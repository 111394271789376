
import React from "react"
import { useEffect, useState } from 'react'
import classnames from "classnames"

import "./GameNim.scss"
import IconClose from "../../assets/icons/IconClose"
import ImgStone from "../../assets/img/stone.png"

// UI components
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'

// tools
import noScrolling from "../../helpers/no-scrolling"


//###########################################################################################################
//###########################################################################################################
//###########################################################################################################
class GameData 
{
	maxMoves = 3
	id = '[GameNimData]'
	level = 0
	board = []
	rows = []

	constructor(level = 0, maxMoves = 3)
	{
		this.newGame(level, maxMoves)

		// this.rows= [0, 3, 4, 0, 0]
		// this._setMaxMoves(3)
		// this.makeMove()
	}

	randValue = (min, max) => { return Math.floor( Math.random() * (max - min + 1) + min ) }

	newGame = (level, maxMoves = 3) => {
		this._setLevel(level)
		this._setMaxMoves(maxMoves)
	}

	_setLevel = (level) => {

		this.level = level
		switch (level) 
		{
			case 0:
				this.rows = [1, 3, 5, 7]
				break
			case 1:
				this.rows = [3, 5, 7, 8, 9]
				break
			case 2: 
				this.rows = [
					this.randValue(4, 7), 
					this.randValue(5, 8), 
					this.randValue(6, 9), 
					this.randValue(7, 9), 
					this.randValue(8, 9)
				]
				break
			default:
				console.error(`${this.id} Error: Unknown level '${level}'!`)
				break
		}
		this.board = [...this.rows]
	}

	_setMaxMoves = (maxMoves) => { 		// maxMoves == 0, dann so viele Steine nehmen, wie möglich
		this.maxMoves = maxMoves
		if ( maxMoves <= 0 )
		{
			let maxValue = 0
			for(let each of this.rows)
			{
				if ( each > maxValue ) maxValue = each
			}
			this.maxMoves = maxValue
		}
	}

	getBestMove = () => {
		let hints = this._getBestMove()
		// if ( misere ) hints = Marienbad(hints);		// misere : Spielvariante, wenn der den letzten Stein verliert
		return hints
	}

	_getBestMove = () => {
		const numRows = this.rows.length
		let mod4 = Array(numRows)			// mod4 : 4 steht für 3+1, d.h. threshold = this.maxMoves + 1, wenn this.maxMoves = 3
		let hints = Array(numRows)
		let sum = 0
		let threshold = this.maxMoves + 1

		let numWinningMoves = 0

		for( let rowIdx = 0; rowIdx < numRows; rowIdx++ )
		{
			mod4[rowIdx] = this.rows[rowIdx] % threshold
			sum = sum ^ mod4[rowIdx]
			hints[rowIdx] = 0
		}

		if ( sum )
		{
			for( let rowIdx = 0; rowIdx < numRows; rowIdx++ )
			{
				let targetValue = sum ^ mod4[rowIdx]
				if ( this.rows[rowIdx]>targetValue && mod4[rowIdx]!==targetValue && targetValue<threshold )
				{
					hints[rowIdx] = (this.rows[rowIdx] - targetValue) % threshold
					numWinningMoves += 1
				}
			}
		}

		return {
			hints,
			numWinningMoves
		}
	}

	makeMove = () => {
		const numRows = this.rows.length
		const bestMoves = this.getBestMove()
		let numStonesToTake = 0
		let rowIdx

		console.log(">>> bestMoves", bestMoves)

		if ( bestMoves.numWinningMoves )
		{
			// console.log("-------------------- A win")
			rowIdx = this.randValue(0, numRows-1)
			while ( bestMoves.hints[rowIdx] === 0 )
			{
				rowIdx = this.randValue(0, numRows-1)
			}
			numStonesToTake = bestMoves.hints[rowIdx]
		}
		else
		{
			// console.log("-------------------- B loose")
			rowIdx = this.randValue(0, numRows-1)
			while ( this.rows[rowIdx] === 0 )
			{
				rowIdx = this.randValue(0, numRows-1)
			}

			numStonesToTake = 1		// jedes 3. Mal nur 1 Stein
			if ( this.randValue(1, 3) > 1 )
			{
				let max = Math.min( this.rows[rowIdx], this.maxMoves )
				if ( max > 3 ) max = Math.floor(max / 1.3) 	// bei mehr als 3 Steine nie die ganze Reihe leer räumen
				numStonesToTake = this.randValue(1, max)
			}
		}

		this.rows[rowIdx] -= numStonesToTake
		console.log(`> row #${rowIdx} num: ${numStonesToTake}`, this.rows)
	}
}

//=====================================================================================
let gameData = new GameData()


//###########################################################################################################
//###########################################################################################################
//###########################################################################################################
export default function GameNim() 
{
	const maxMoves = 3
	const [state, setState] = useState('')

	//#################################################################################
	useEffect(
		() => {
			noScrolling.disable_scrolling()

			initNewGame( 2 )
		},
		[]
	)

	//#################################################################################
	const cb_onClick_cta = ( url ) => {

		const elemList = document.getElementsByClassName('GameNim')
		let elem = null
		if ( elemList.length > 0 )
		{
			elem = elemList.item(0)
			elem.classList.add('fade-out')
		}
		setTimeout(() => {

			noScrolling.enable_scrolling()

			if ( elem !== null )
			{
				elem.classList.add('hide')
			}
		}, 300)
	}

	//#################################################################################
	const initNewGame = (level) => {
	
		// gameData.newGame(level, maxMoves)
		gameData = new GameData(2, maxMoves)
		console.log(">>>>>> UI.initNewGame", gameData)
		setState( 'init' )
	}

	//#################################################################################
	const renderGameRow = (row_idx) => {
		
		let cols = []
		for( let i = 0; i < gameData.board[row_idx]; i++ )
		{
			cols.push(
				<div 
					id={`col_${row_idx}${i}`}
					className='board-col' 
					onClick={() => console.log('++ onClick')}
				>
					{i <= gameData.rows[row_idx]
						? <img className="stone" src={ImgStone} />
						: null
					}
				</div>
			)
		}

		return (
			<div id={`row_${row_idx}`} className='board-row'>
				{cols}
			</div>
		)
	}

	//#################################################################################
	const renderBoard = () => {

		let game_rows = []
		for(let i = 0; i < gameData.board.length; i++ )
		{
			game_rows.push( renderGameRow(i) )
		}

		return (
			<div className='board-root-container'>
				<div className='board'>
					{game_rows}
				</div>
			</div>
		)
	}

	//#################################################################################
	const renderWindow = () => {

		return (
			<div className={classnames("game-container")}>
				<div className='icon-close' onClick={e => { cb_onClick_cta(null) }}>
					<IconClose className="icon" />
				</div>
				<div className={classnames("game-body")}>
					{renderBoard()}
				</div>
			</div>
		)
	}

	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return (<>
		<div className='GameNim' state={state}>
			{renderWindow()}
		</div>
	</>)
}
