import {
	BrowserRouter as Router,
	Routes,
	Route,
	// useNavigate
} from "react-router-dom"
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from "classnames"

import './App.scss';

// pages
import ArticleOverview from "./pages/ArticleOverview"
import Login from "./pages/Auth/Login/Login"
import Logout from "./pages/Auth/Logout/Logout"
import ForgotYourPassword from "./pages/Account/ForgotYourPassword/ForgotYourPassword"
import ChangeYourPassword from "./pages/Account/ChangeYourPassword/ChangeYourPassword"
import CreateNewAccount from "./pages/Account/CreateNewAccount/CreateNewAccount"
import Article from "./pages/Article"
import ArticlePreview from "./pages/ArticlePreview"
import Bookmarks from "./pages/Bookmarks/Bookmarks"
import History from "./pages/History/History"
import SearchPage from "./pages/SearchPage/SearchPage"
import Calendar from "./pages/Calendar/Calendar"
import CalendarEventDetail from "./pages/CalendarEventDetail/CalendarEventDetail"
import CalendarEventDetailPreview from "./pages/CalendarEventDetail/CalendarEventDetailPreview"
import HelpPage from "./pages/HelpPage/HelpPage"
import NotFound404 from "./pages/NotFound404/NotFound404"
import MaintenancePage from "./pages/MaintenancePage/MaintenancePage"
import RedirectPage from "./pages/RedirectPage/RedirectPage"
// legal pages
import AboutPage from "./pages/LegalPages/AboutPage"
import ImprintPage from "./pages/LegalPages/ImprintPage"
import PrivacyPolicyPage from "./pages/LegalPages/PrivacyPolicyPage"
//
import CropImagePage from "./pages/CropImagePage"
import TrendExplorerSearch from "./pages/TrendExplorerSearch/TrendExplorerSearch"
import GoogleTrendExplorer from "./pages/GoogleTrendExplorer/GoogleTrendExplorer"
import TwitterTrendExplorer from "./pages/TwitterTrendExplorer/TwitterTrendExplorer"
import TikTokTrendExplorer from "./pages/TikTokTrendExplorer/TikTokTrendExplorer"
// import WikiTrendExplorer from "./pages/WikiTrendExplorer/WikiTrendExplorer"

// components
import HtmlHeader from "./components/HtmlHeader/HtmlHeader"
import HubHeader from './components/HubHeader/HubHeader'
import Navigation from "./components/Navigation/Navigation"
import Footer from "./components/Footer/Footer"
import MinimalFooter from "./components/Footer/MinimalFooter"
//
import routes from './routes/routes'
import localData from "./helpers/localData"
import JSONEnDecoder from "./helpers/JSONEnDecoder"
import User from "./models/User"
import { logIn, logOut } from "./redux/functions/user_slice"
import Customer from "./models/Customer"
import { clearCustomer, setCustomer } from "./redux/functions/customer_slice"

import dayjs from 'dayjs'
import _ from 'lodash'
import axios from "axios"
import Semaphore from "./helpers/Semaphore"
import cmsConnect from "./helpers/cmsConnect"

//=============================================================================================================================
// const dayjs = require('dayjs')	...... siehe weiter oben: import dayjs from 'dayjs'
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
//
let reload_intervalID = null

//=============================================================================================================================
export default function App() 
{
	const ENABLE_REDIRECT_PAGE = true

  	const dispatch = useDispatch()
	const [pingErr, setPingErr] = useState(false)

	//#################################################################################
	useEffect(
		() => {
			//-------------------------------------------------------------------------
			const sendPing = async () => {
				const path = process.env.REACT_APP_API_URL
				// const ping_res = await cmsConnect.GET_queue('/api/v/ping', ...) .... eigentlich MUSS man dieses Weg für ein Request machen, aber in diesen Fall mache ich eine Ausnahme, weil diese API keine AUTH benötigt und weil es nur ein PING-request ist
				axios.get(path + '/api/v/ping')
					.then( response => {
						// console.log(response.data)
						if ( reload_intervalID !== null )
						{
							alert("timer stopped A")
							console.log("maintenance info :: reload timer sopped")
							clearInterval(reload_intervalID)
							reload_intervalID = null
						}
					})
					.catch( error => {
						//======================================================
						// Diese funktioniert wie folgt:
						//		1. wenn der Server in der Lage ist, eine Antwort zurückzuliefern, dann ist
						//			tyoeof(error.response) == 'object', mehr Infos in <error.response.data>
						//		2. wenn der Server nicht online ist und/oder keine Netzwerkverbindung auf dem Client, dann ist
						//			tyoeof(error.response) == 'undefined' ... was auch Sinn macht, weil der Server (in der verherigen Zeile genannten Gründen) nicht Antworten kann
						//======================================================
						// console.log("-- >>>>>>>>>>>>>>>>>>>>>>>", Object.keys(error))
						// console.log("-- >>>>>>>>>>>>>>>>>>>>>>>", error.response.data.error)
						console.log("PING error ::", 
							(typeof(error.response)==='undefined' || error.response===null ? 'no nerver response =>' : 'server response:'), error.response,
							' --- response data:', _.get(error, 'response.data', undefined)
						)
						if ( reload_intervalID === null )
						{
							reload_intervalID = setInterval(
								() => {
									console.log("reloading ...")
									window.location.reload()
								},
								60 * 1000
							)
						}
						setPingErr(true)
					})
			}
			sendPing()

			//-------------------------------------------------------------------------
			localData().updateCustomerColors()

			//-------------------------------------------------------------------------
			const timer = setInterval(() => {
				if ( window.location.href.toLowerCase().includes("/auth/login") ) return	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
				//
				const current_ts = dayjs().millisecond(0)
				//
				const orig_expiry_ts = _.get(localData().getUser(), 'expiryTS_rt', null)
				if ( orig_expiry_ts === null ) return		// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
				const tmp_ts = new Date(0).setUTCSeconds( orig_expiry_ts )
				const expiry_ts = dayjs(tmp_ts).millisecond(0)
				//
				// console.log(">>>>", current_ts.format('YYYY-MM-DD HH:mm:ss'), expiry_ts.format('YYYY-MM-DD HH:mm:ss'))
				if ( current_ts.isSameOrAfter(expiry_ts) )
				{
					localData().setAutoLogout( expiry_ts )
					routes.navToRoute( routes.logout )
				}
			}, 1000)

			//-------------------------------------------------------------------------
			//-------------------------------------------------------------------------
			//-------------------------------------------------------------------------
			return () => {
				clearTimeout(timer)
				//
				if ( reload_intervalID !== null )
				{
					clearInterval(reload_intervalID)
					reload_intervalID = null
				}
			}
			//-------------------------------------------------------------------------
		},
		[]
	)
	/*
	useEffect(
		() => {
			const current_path = document.location.pathname
			const tmp = localData().getPage()
			if ( tmp.ts )
			{
				tmp.ts = ((new Date()) - (new Date(tmp.ts))) / 1000			// val / 1000 = in Sekunden umwandeln
			}
			console.log(">>>>>>>>>>> App", current_path, tmp)
			let data = ( /^\/article\/\d+/im.test(current_path) 
				?	{
						url: document.location.pathname,
						ts: (new Date())
					}
				:	{}
			)
			localData().setPage( data )
		},
		[]
	)
	*/

	//#################################################################################
	function defaultPage(content, display_header = false) {
		return (<>
			{ display_header === true
				?	<header>
						<HubHeader />
					</header>
				:	null
			}
			{content}
			<Footer animated />
		</>)
	}
	function getArticleOverviewContent() {
		return (<>
			<ArticleOverview />
			<Footer animated />
		</>)
	}
	function getArticleContent() {
		return (<>
			<Article type="article" />
			<Footer /*animated*/ />
		</>)
	}
	function getArticlePreviewContent() {
		return (<>
			<ArticlePreview type="article" />
		</>)
	}
	function getQuoteContent() {
		return (<>
			<Article type="quote" />
			<Footer /*animated*/ />
		</>)
	}
	function getQuotePreviewContent() {
		return (<>
			<ArticlePreview type="quote" />
		</>)
	}
	function getBookmarksContent () {
		return (<>
			<Bookmarks />
			<Footer /*animated*/ />
		</>)
	}
	function getHistoryContent () {
		return (<>
			<History />
			<Footer /*animated*/ />
		</>)
	}
	function getSearchContent () {
		return (<>
			<SearchPage />
			<Footer /*animated*/ />
		</>)
	}
	function getCalendarContent () {
		return (<>
			<Calendar />
			<Footer /*animated*/ />
		</>)
	}
	function getCalendarEventDetail () {
		return (<>
			<CalendarEventDetail />
			<Footer /*animated*/ />
		</>)
	}
	function getEventDetailPreviewContent() {
		return (<>
			<CalendarEventDetailPreview />
		</>)
	}
	function getHelpPageContent () {
		return (<>
			<HelpPage />
			<Footer /*animated*/ />
		</>)
	}
	function getForgotYourPasswordContent() {
		return (<>
			<header>
				<HubHeader />
			</header>
			<ForgotYourPassword />
		</>)
		// return (<>
		// 	<header>
		// 		<HubHeader />
		// 		{/* <Navigation /> */}
		// 	</header>
		// 	<ForgotYourPassword />
		// 	{/* <Footer hide_logoAndSubscribe={true} /> */}
		// </>)
	}
	function getChangeYourPassword()
	{
		return (<>
			<header>
				<HubHeader />
			</header>
			<ChangeYourPassword />
		</>)
	}
	function getCreateNewAccountContent()
	{
		return (<>
			<header>
				<HubHeader />
			</header>
			<CreateNewAccount />
		</>)
	}
	function get404Notfound() {
		return (<>
			<NotFound404 />
			<Footer /*animated*/ />
		</>)
	}


	//#################################################################################
	function restoreForwardData()
	{
		if ( document.location.hash.length > 0 )
		{
			const query = document.location.hash.substring(1)			// => https://<domain>#forward=<crypted data>
			if ( !query.toLowerCase().startsWith('forward') ) return	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> return!
			let params = {}
			query.split('&').forEach((e) => {
				const p = e.split('=')
				if ( p.length === 2 )
				{
					params[p[0]] = p[1]
				}
			})
			let forward = params['forward']
			if ( forward !== null )
			{
				const json = JSONEnDecoder.decodeJSON(forward)
				if ( json != null )
				{
					if ( json.user != null ) dispatch( logIn(User(json.user)) )
					if ( json.customer != null ) dispatch( setCustomer(Customer(json.customer)) )
				}
			}
			if ( document.location.hash.length > 0 ) document.location.hash = ''
		}
	}

	//#################################################################################
	function isPreviewArticleOrEvent()
	{
		let pathname = document.location.pathname
		// let params = document.location.search
		return (
			pathname.startsWith(routes.preview.article)
			|| pathname.startsWith(routes.preview.quote)
			|| pathname.startsWith(routes.preview.event)
		)
	}

	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------

	if ( process.env.NODE_ENV === "development" )
	{
		console.error("#########################################\n#########################################\n#########################################\n\n- Forward-Login ist drin Forward-URL noch einbauen\n- CropImage eingebaut - noch nicht fertig\n- User-Table: Login-Timestamp speichern (falls kein Feld existiert, der das schon kann). zB. LastLogin-Feld der immer aktualisiert wird, wenn der User die Seite Besucht hat bzw. sich eingelogt hat\n- img lazy load im overview einbauen?\n- Footer: Subscribe einbauen\n- Zitat: onClick startet eine Suche nach den entsprechenden Tags\n- 'Related articles' Komponente (in Artikel- + Zitat-Detai)\n- Artikelmodule ext. + interne Bilder: Beschreibung wird noch nicht angezeigt\n\n#########################################\n#########################################\n#########################################\n")
	}
	
	/*
	console.log("encoded article:", Buffer.from('/api/v/article/${topicId}?%C2%BB@previewUID=preview-user').toString('base64'))				// => L2FwaS92L2FydGljbGUvJHt0b3BpY0lkfT8lQzIlQkJAcHJldmlld1VJRD1wcmV2aWV3LXVzZXI=
	console.log("encoded event:", Buffer.from('/api/v/event/${data_id}?%C2%BB@previewUID=preview-user').toString('base64'))					// => L2FwaS92L2V2ZW50LyR7ZGF0YV9pZH0/JUMyJUJCQHByZXZpZXdVSUQ9cHJldmlldy11c2Vy
	// const url = 'L2FwaS92L2FydGljbGUvJHt0b3BpY0lkfT8lQzIlQkJAcHJldmlld1VJRD1wcmV2aWV3LXVzZXI='
	const url = 'L2FwaS92L2V2ZW50LyR7ZGF0YV9pZH0/JUMyJUJCQHByZXZpZXdVSUQ9cHJldmlldy11c2Vy'
	const topicId = 133
	const decoded = Buffer.from(url, 'base64').toString('ascii').replace('${topicId}', topicId)
	console.log(">>> decoded", decoded)
	*/

	//#################################################################################
	restoreForwardData()
	// localData().validateLastVisit() ... wurde durch Auto-Logout ersetzt, weil neues Login mit Refresh-Token

	if ( ENABLE_REDIRECT_PAGE )
	{
		return (<>
			<RedirectPage />
			</>)
	}

	//################################################################################# Maintenance Page
	// console.log(window.location.href, window.location.pathname)
	if ( pingErr )
	{
		let prioritisedRouteIdx = [routes.about, routes.imprint, routes.privacy_policy].indexOf( window.location.pathname )
		if ( prioritisedRouteIdx < 0 )		// wenn aktuelle Route nicht in der Liste der priorisierten Routen existiert, dann Maintenance-Seite normal anzeigen
		{
			return (<>
				<header>
					<HubHeader />
				</header>
				<MaintenancePage />
				<MinimalFooter />
				</>)
		}
	}

	//################################################################################# public pages - START
	if ( localData().getUser()==null && !isPreviewArticleOrEvent() )		// nur dann zum Login springen, wenn User==null und KEIN Preview
	{
		switch ( window.location.pathname )
		{
			//------------------------------------------------------- forgot your password	........... diese Page sollte auch über <Routes> erreichbar sein!
			case routes.forgotYourPassword: 
				return getForgotYourPasswordContent()
			//------------------------------------------------------- reset your password	........... diese Page sollte auch über <Routes> erreichbar sein!
			case routes.changeYourPassword:
				return getChangeYourPassword()
			//------------------------------------------------------- create new account	........... diese Page sollte auch über <Routes> erreichbar sein!
			case routes.createNewAccount:
				return getCreateNewAccountContent()
			

			//------------------------------------------------------- legal | about			........... diese Page sollte auch über <Routes> erreichbar sein!
			case routes.about:
				return defaultPage(<AboutPage />, true)
			//------------------------------------------------------- legal | about			........... diese Page sollte auch über <Routes> erreichbar sein!
			case routes.imprint:
				return defaultPage(<ImprintPage />, true)
			//------------------------------------------------------- legal | about			........... diese Page sollte auch über <Routes> erreichbar sein!
			case routes.privacy_policy:
				return defaultPage(<PrivacyPolicyPage />, true)
			
			
			//------------------------------------------------------- DEFAULT: login
			default:
				return (<>
					<header>
						<HubHeader />
						{/* <Navigation /> */}
					</header>
					<Login/>
					{/* <Footer hide_logoAndSubscribe={true} /> */}
				</>)
		}
	}
	//################################################################################# public pages - END
	
	return (
		<Router>
			<HtmlHeader />
			<header>
				<HubHeader />
				{ !isPreviewArticleOrEvent()
					? <Navigation />
					: null
				}
			</header>
			<div className={classNames('base-container')}>
				<Routes>
					<Route exact path={routes.home} element={getArticleOverviewContent()}></Route>
					<Route exact path={routes.login} element={<Login/>}></Route>
					<Route exact path={routes.logout} element={<Logout/>}></Route>
					<Route exact path={routes.forgotYourPassword} element={getForgotYourPasswordContent()}></Route>		{/* diese Page muss auch im switch-case Block eingefügt werden, weil die Page auch ohne Passwort erreichbar sein muss/soll */}
					<Route exact path={routes.changeYourPassword} element={getChangeYourPassword()}></Route>			{/* diese Page muss auch im switch-case Block eingefügt werden, weil die Page auch ohne Passwort erreichbar sein muss/soll */}
					<Route exact path={routes.createNewAccount} element={getCreateNewAccountContent()}></Route>			{/* diese Page muss auch im switch-case Block eingefügt werden, weil die Page auch ohne Passwort erreichbar sein muss/soll */}
					<Route path={routes.article} element={getArticleContent()}></Route>
					<Route path={routes.article_preview} element={getArticlePreviewContent()}></Route>	{/* Article Preview */}
					<Route path={routes.quote} element={getQuoteContent()}></Route>
					<Route path={routes.quote_preview} element={getQuotePreviewContent()}></Route>	{/* Quote Preview */}
					<Route exact path={routes.bookmarks} element={getBookmarksContent()}></Route>
					<Route exact path={routes.history} element={getHistoryContent()}></Route>
					<Route exact path={routes.search} element={getSearchContent()}></Route>
					<Route exact path={routes.calendar} element={getCalendarContent()}></Route>
					<Route exact path={routes.calendar_detail} element={getCalendarEventDetail()}></Route>
					<Route exact path={routes.calendar_detail_preview} element={getEventDetailPreviewContent()}></Route>	{/* Event Preview */}
					<Route path={routes.help_page} element={getHelpPageContent()}></Route>

					<Route exact path={routes.about} element={defaultPage(<AboutPage />, true)}></Route>					{/* diese Page muss auch im switch-case Block eingefügt werden, weil die Page auch ohne Passwort erreichbar sein muss/soll */}
					<Route exact path={routes.imprint} element={defaultPage(<ImprintPage />, true)}></Route>				{/* diese Page muss auch im switch-case Block eingefügt werden, weil die Page auch ohne Passwort erreichbar sein muss/soll */}
					<Route exact path={routes.privacy_policy} element={defaultPage(<PrivacyPolicyPage />, true)}></Route>	{/* diese Page muss auch im switch-case Block eingefügt werden, weil die Page auch ohne Passwort erreichbar sein muss/soll */}

					<Route exact path={routes.crop_image} element={<CropImagePage />}></Route>
					<Route exact path={routes.trendExplorer_search} element={<TrendExplorerSearch />}></Route>
					<Route excat path={routes.googleTrends_search} element={<GoogleTrendExplorer />}></Route>
					<Route excat path={routes.twitterTrends_search} element={<TwitterTrendExplorer />}></Route>
					<Route excat path={routes.tikTokTrends_search} element={<TikTokTrendExplorer />}></Route>
					{/* <Route excat path={routes.wikiTrends_search} element={<WikiTrendExplorer />}></Route> */}
					
					<Route path="/*" element={get404Notfound()}></Route>
				</Routes>
			</div>
			{/* <Footer animated /> */}
		</Router>
	);
}

