
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// import advanced from 'dayjs/plugin/advancedFormat'		// für dayjs().format('z') ... Buchstabe 'z'

dayjs.extend(timezone)
dayjs.extend(utc)
// dayjs.extend(advanced)

//#################################################################################
class CalendarDate
{
	//=============================================================================
	//	Inputs:
	//		date: {String} := 'YYYY-MM-DD'
	//		time: {String} := 'HH:mm'
	//		tz  : {String} ("America/New_York", "Europe/Berlin", ...)
	// Output:
	//		{dayjs}
	//
	static newDateWithTimeZone(date, time, tz)
	{
		const valueUTC = dayjs().tz(tz).format('ZZ')
		const date_str = `${date} ${time} ${valueUTC}`
		const d = dayjs(date_str, 'YYYY-MM-DD HH:mm ZZ')
		return d
	}

	//=============================================================================
	//	Inputs:
	//		date: {String} := 'YYYY-MM-DD HH:mm'
	//		tz  : {String} ("America/New_York", "Europe/Berlin", ...)
	// Output:
	//		{dayjs}
	//
	static newDateWithTimeZone_fullDate(date, tz)
	{
		const valueUTC = dayjs().tz(tz).format('ZZ')
		const date_str = date + ` ${valueUTC}`
		const d = dayjs(date_str, 'YYYY-MM-DD HH:mm ZZ')
		return d
	}
}

export default CalendarDate