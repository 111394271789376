import { useEffect /*, useState, useRef*/ } from "react"
import PropTypes from 'prop-types'
import classnames from "classnames"

import './EventEntry.scss'

import CardMedia from '@mui/material/CardMedia'

// tools
import dateFormat from "../../../helpers/dateFormat"
import StrapiImage from "../../../helpers/StrapiImage"

export default function EventEntry({data, fadeInDelay, cb_onClick}) 
{
	const format_date = dateFormat()

	//#################################################################################
	/*)
	useEffect(
		() => {
		}, 
		[]
	)
	*/

	//#################################################################################
	const onClick = (e) => {
		if ( typeof(cb_onClick)!=='undefined' && cb_onClick!==null )
		{
			cb_onClick(data)
		}
	}
	
	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return <>
		<div 
			className={classnames('event-entry', 'fadeIn')} 
			eid={`ev-${data.id}`} 
			style={{animationDelay: `${fadeInDelay}s`}}
			onClick={e => onClick(e)}
		>
			<div className="img-container">
				<CardMedia
					component="img"
					className="image"
					image={StrapiImage.getImage(data.hero_1x1 || null)}
					alt={data.title}
				/>
			</div>
			<div className="description-container">
				<div className="title" dangerouslySetInnerHTML={{__html: data.title}} />
				<div className="date">{format_date.calendar_formatDatePeriod(data.date_from, data.date_to, data.date_without_time)}</div>
			</div>
		</div>
	</>
}

EventEntry.propTypes = {
	data: PropTypes.object,
	fadeInDelay: PropTypes.number,
	cb_onClick: PropTypes.func,
	// fetchInProgress: PropTypes.bool,
	// cb_onShowMore: PropTypes.func,
}
  
EventEntry.defaultProps = {
	data: {},
	fadeInDelay: 0,
	cb_onClick: null,
	// fetchInProgress: false,
	// cb_onShowMore: null,
}