
const mstudio_placeholder = {
	MSTUDIO_NAME: "[m]STUDIO",
	MSTUDIO_GMBH: "[m]STUDIO GmbH",
	MSTUDIO_ADDR: "Völklingerstr. 33",
	MSTUDIO_PLZ_ORT: "40221 Düsseldorf",
	MSTUDIO_TEL: "+49 211 540 369 - 0",
	MSTUDIO_EMAIL: "relevance@mstudioagency.de",
	MSTUDIO_URL: "https://mstudioagency.de",
	//
	DEFAULT_HELP_PAGE_TITLE: "How-to User Guide",
}

export default mstudio_placeholder
