import { useEffect, useState /*, useRef*/ } from "react"
import { useParams /*, useNavigate*/ } from "react-router-dom"
import PropTypes from 'prop-types'
import classnames from "classnames"
import { Buffer } from "buffer"

import './CalendarEventDetail.scss'

import PaddingTop from "../../components/PaddingTop/PaddingTop"
import HtmlHeader from '../../components/HtmlHeader/HtmlHeader'
import EventDetail from "../../components/EventDetail/EventDetail"

// tools
import cmsConnect from "../../helpers/cmsConnect"
import JSONEnDecoder from "../../helpers/JSONEnDecoder"

export default function CalendarEventDetailPreview() 
{
	const { eventId } = useParams()

	const [event, setEvent] = useState({})

	//#################################################################################
	useEffect(
		() => {
			fetchEvent()
		}, 
		[]
	)

	//#################################################################################
	const fetchEvent = async () => {

		const decodedData = JSONEnDecoder.decodeJSON( eventId )
		// console.log(">>>>>>>>>>>>> fetchEvent", decodedData)
		if ( typeof(decodedData)!=="undefined" && decodedData!==null && typeof(decodedData)==='object' && decodedData.id )
		{
			const url = 'L2FwaS92L2V2ZW50LyR7ZGF0YV9pZH0/JUMyJUJCQHByZXZpZXdVSUQ9cHJldmlldy11c2Vy'		// den base64 String habe ich in App.js kodiert :: '/api/v/event/${data_id}?%C2%BB@previewUID=preview-user'
			const decoded_url = Buffer.from(url, 'base64').toString('ascii').replace('${data_id}', decodedData.id)
			// console.log(">>>>>> decoded_url", decoded_url)
			// const res = await cmsConnect().GET(`/api/v/event/${decodedData.id}?%C2%BB@previewUID=preview-user`)
			await cmsConnect().GET_queue(
				decoded_url,
				(res) => {
					if ( typeof(res)!=='undefined' && typeof(res.data)!=='undefined' ) 
					{
						// console.log(">>>>>> fetchEvent :: res", res.data)
						setEvent(res.data)
					}
				}
			)
		}
	}
	
	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return <>
		<HtmlHeader subtitle={event.title} />
		<PaddingTop />
		<div className="CalendarEventDetail">
			<EventDetail event={event} isPreview={true} />
		</div>
	</>
}

CalendarEventDetailPreview.propTypes = {
	// date: PropTypes.object,
	// fetchInProgress: PropTypes.bool,
	// cb_onShowMore: PropTypes.func,
}
  
CalendarEventDetailPreview.defaultProps = {
	// date: new Date(),
	// fetchInProgress: false,
	// cb_onShowMore: null,
}