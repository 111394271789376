import { useEffect, useState } from 'react'

import './RedirectPage.scss'


let intervalID = null

export default function RedirectPage() 
{
	const [url, setUrl] = useState({
		from: {domain: 'relevancehub', TLD: '.net'},
		to: {domain: 'relevancehub', TLD: '.de'},
	})
	const [count, setCount] = useState(10)

	//#################################################################################
	useEffect(
		() => {
			//----------------------------------------------------
			const host = window.location.host
			// const host = "mediacom.relevancehub.net"
			// const host = "mediacom.hungry-cannon.92-205-59-162.plesk.page"
			//
			const parts = host.split('.')
			if ( parts.length > 0 )
			{
				if ( parts[0].trim().toLowerCase() === 'mediacom' )
				{
					parts[0] = 'essencemediacom'
				}
			}
			const result = ( parts.length >= 2
				? 	{
						from: { domain: `${parts[0]}.${parts.slice(1,-1).join('.')}`, TLD: `.${parts[parts.length-1]}` },
						to: ( parts[parts.length-1].trim().toLowerCase() === 'net'
							? { domain: `${parts[0]}.${parts.slice(1,-1).join('.')}`, TLD: '.de' }
							: { domain: `${parts[0]}.relevancehub`, TLD: `.de` }
						)
					}
				:	{
						from: {domain: <span className="err">{`invalid domain ${parts[0]}`}</span>, TLD: ''},
						to: {domain: <><span className="err">{'<customer>'}</span>{'.relevancehub'}</>, TLD: '.de'},
					}
			)
			// console.log(">>> parts", parts)
			// console.log(">>> result", result)
			setUrl( result )

			//----------------------------------------------------
			intervalID = setInterval(
				() => {
					setCount( (count) => count - 1)
				},
				1000
			)

			//====================================================
			return () => {
				if ( intervalID !== null )
				{
					clearInterval(intervalID)
					intervalID = null
				}
			}
		},
		[]
	)

	useEffect(
		() => {
			if ( count <= 0 )
			{
				if ( intervalID !== null )
				{
					clearInterval(intervalID)
					intervalID = null
				}
				window.location.href = `https://${url.to.domain}${url.to.TLD}`
			}
		},
		[count, url]
	)

	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	// console.log("----- Redirect page", window.location.href)
	//
    return (<>
		<div className="RedirectPage-PaddingTop" />
		<div className="RedirectPage">
			<div className="root-container">
				<div className="title">Information!</div>
				<p>We have changed our domain<br/><span className="light">{url.from.domain}<b>{url.from.TLD}</b></span><br/>to<br/><span className="light">{url.to.domain}<b>{url.to.TLD}</b></span> !</p>
				<p>You will be redirected to the new domain in <b>{count} seconds</b><br/>or click <a href={`https://${url.to.domain}${url.to.TLD}`}>here</a> to be redirected immediately</p>
			</div>
		</div>
	</>)
}