import { useEffect /*, useState, useRef*/ } from "react"
// import PropTypes from 'prop-types'
import classnames from "classnames"

import './NoEventsEntry.scss'


export default function NoEventsEntry({data}) 
{
	//#################################################################################
	/*)
	useEffect(
		() => {
		}, 
		[]
	)
	*/
	
	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return <>
		<div className="no-events-entry">
			{/* There are no events available for this day */}
			No events available for this day
		</div>
	</>
}

NoEventsEntry.propTypes = {
	// entries: PropTypes.object,
	// fetchInProgress: PropTypes.bool,
	// cb_onShowMore: PropTypes.func,
}
  
NoEventsEntry.defaultProps = {
	// entries: [],
	// fetchInProgress: false,
	// cb_onShowMore: null,
}