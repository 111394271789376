import React from "react";

const IconCalArrowRight = ({
//   style = {},
  fill = "#fff",
//   width = "100%",
  className = "",
  viewBox = "0 0 6.06 11"
}) => (
	<svg
		className={`svg-icon ${className || ""}`}  
		x="0px" 
		y="0px"
	 	viewBox={viewBox} 
	>
		<polygon className="st0" points="5.5,11 0,5.5 5.5,0 6.06,0.56 1.12,5.5 6.06,10.44 "/>
	</svg>
);

/*
<svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M15 18.5l-5-5.7 1.4-1.3 3.6 4.1 3.6-4.1 1.4 1.3-5 5.7z"
    />
  </svg>
  */

export default IconCalArrowRight;
