import { useEffect, useState /*, useRef*/ } from "react"
import { useParams /*, useNavigate*/ } from "react-router-dom"
import PropTypes from 'prop-types'
import classnames from "classnames"

import './CalendarEventDetail.scss'

import PaddingTop from "../../components/PaddingTop/PaddingTop"
import HtmlHeader from '../../components/HtmlHeader/HtmlHeader'
import EventDetail from "../../components/EventDetail/EventDetail"

// tools
import cmsConnect from "../../helpers/cmsConnect"

export default function CalendarEventDetail() 
{
	const { eventId } = useParams()

	const [event, setEvent] = useState({})

	//#################################################################################
	useEffect(
		() => {
			fetchEvent()
		}, 
		[]
	)

	//#################################################################################
	const fetchEvent = async () => {

		await cmsConnect().GET_queue(
			`/api/v/event/${eventId}`,
			(res) => {
				if ( typeof(res)!=='undefined' && typeof(res.data)!=='undefined' ) 
				{
					// console.log(">>>>>> fetchEvent :: res", res.data)
					setEvent(res.data)
				}
			}
		)
	}
	
	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return <>
		<HtmlHeader subtitle={event.title} />
		<PaddingTop />
		<div className="CalendarEventDetail">
			<EventDetail event={event} />
		</div>
	</>
}

CalendarEventDetail.propTypes = {
	// date: PropTypes.object,
	// fetchInProgress: PropTypes.bool,
	// cb_onShowMore: PropTypes.func,
}
  
CalendarEventDetail.defaultProps = {
	// date: new Date(),
	// fetchInProgress: false,
	// cb_onShowMore: null,
}