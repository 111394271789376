
import dayjs from 'dayjs'
import removeMarkdown from "markdown-to-text"

//#################################################################################
class Event2Cal
{
	static getCalEntry(event, options = ["Apple", "Google", "iCal", "Microsoft365", "Outlook.com", "MicrosoftTeams", "Yahoo"])
	{
		function hasKey(key)
		{
			const e = event[key]
			return ( typeof(e)!=='undefined' && e!==null )
		}
		const description = this._getDescription(event)
		let result = {
			name: event.title,
			startDate: dayjs(event.start_date).format('YYYY-MM-DD'),
			endDate: dayjs(event.end_date).format('YYYY-MM-DD'),
			options: options,
			timeZone: "Europe/Berlin",
			trigger: "click",
			iCalFileName: `Reminder-Event-${event.title.replace(/\s+/im, '-')}`,
		}
		if ( description.length > 0 ) result.description = description
		if ( hasKey('start_time') && event.start_time.length>0 ) result.startTime = event.start_time
		if ( hasKey('end_time') && event.end_time.length>0 ) result.endTime = event.end_time
		return result
	}

	static _getDescription(event)
	{
		let result = ''
		if ( event===null || typeof(event.content)==='undefined' || event.content===null )
		{
			return result
		}
		for( let each of event.content )
		{
			switch ( each.__component )
			{
				case "content-modules.main-title":
				case "content-modules.title": 
					{
						const text = (each.title || '').trim()
						if ( text.length > 0 )
						{
							result += (result.length > 0 ? '\n' : '') + text + '\n'
						}
					}
					break

				case "content-modules.paragraph-1col":
					{
						const text = (each.text || '').trim()
						if ( text.length > 0 )
						{
							result += (result.length > 0 ? '\n' : '') + removeMarkdown(text) + '\n'
						}
					}
					break

				default:
					break
			}
		}
		return result
	}
}

export default Event2Cal
