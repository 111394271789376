
const PATH_ARTICLE = '/article'
const PATH_QUOTE = '/quote'
const PATH_PREVIEW = '/@preview'
const PATH_CALENDAR = '/calendar'

const routes = {
	preview: {
		article: `${PATH_ARTICLE}${PATH_PREVIEW}`,
		quote: `${PATH_QUOTE}${PATH_PREVIEW}`,
		event: `${PATH_CALENDAR}${PATH_PREVIEW}`
	},
	home: '/',
	article: `${PATH_ARTICLE}/:topicId`,							// => /article/:topicId
	article_preview: `${PATH_ARTICLE}${PATH_PREVIEW}/:topicId`,		// => /article/@preview/:topicId
	quote: `${PATH_QUOTE}/:topicId`,								// => /quote/:topicId
	quote_preview: `${PATH_QUOTE}${PATH_PREVIEW}/:topicId`,			// => /quote/@preview/:topicId
	login: '/auth/login',
	logout: '/auth/logout',
	forgotYourPassword: '/account/forgot-password',
	changeYourPassword: '/account/change-password',
	createNewAccount: '/account/sign-up',
	bookmarks: '/profile/bookmarks',
	history: '/profile/history',
	search: '/search',
	calendar: `${PATH_CALENDAR}`,
	calendar_detail: `${PATH_CALENDAR}/:eventId`,
	calendar_detail_preview: `${PATH_CALENDAR}${PATH_PREVIEW}/:eventId`,
	help_page: `/help`,
	// ???
	crop_image: "/crop/image",						// page existiert
	trendExplorer_search: "/trendexplorer/search",	// page existiert
	googleTrends_search: "/googletrends/search",	// page existiert
	twitterTrends_search: "/twittertrends/search",	// page existiert
	tikTokTrends_search: "/tiktoktrends/search",	// page existiert
	wikiTrends_search: "/wikitrends/search",		// page existiert
	about: '/about',
	imprint: '/imprint',
	privacy_policy: '/privacy_policy',

	navToArticleId: (articleId) => {
		window.location.href = routes.article.replace(':topicId', articleId)
	},

	navToRoute: (route) => {
		window.location.href = route
	}
}

export default routes