
import React from "react"
import { useEffect, useState /*, Link*/ } from 'react'
import classnames from "classnames"

import "./IncaInfluencers.scss"

// Icons
import IconInstagram from '../../../../assets/icons/icon-instagram.svg'
import IconTwitter from '../../../../assets/icons/icon-twitter.svg'
import IconYoutube from '../../../../assets/icons/icon-youtube.svg'
import IconSnapchat from '../../../../assets/icons/icon-snapchat.svg'
import IconPinterest from '../../../../assets/icons/icon-pinterest.svg'
import IconTwitch from '../../../../assets/icons/icon-twitch.svg'
import IconTiktok from '../../../../assets/icons/icon-tiktok.svg'
import IconFacebook from '../../../../assets/icons/icon-facebook.svg'
import IconLinkedin from '../../../../assets/icons/icon-linkedin.svg'
import IconSoundcloud from '../../../../assets/icons/icon-soundcloud.svg'
import IconSpotify from '../../../../assets/icons/icon-spotify.svg'
import IconVimeo from '../../../../assets/icons/icon-vimeo.svg'

// UI components
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// tools
import { user_isOnlyExpert } from '../../../../helpers/userData'

const abbreviate = require('number-abbreviate')

const DB_CHANNELS = [
	'instagram_reach', 
	'twitter_1_reach', 'twitter_2_reach', 
	'youtube_1_reach', 'youtube_2_reach', 
	'snapchat_reach', 
	'pinterest_reach', 
	'twitch_reach', 
	'tiktok_1_reach', 'tiktok_2_reach', 
	'facebook_1_reach', 'facebook_2_reach', 
	'linkedin_reach',
	'soundcloud_reach',
	'spotify_reach',
	'vimeo_reach',
]

export default function IncaInfluencers({ user, influencers }) 
{
	const [expanded, setExpanded] = React.useState(false)

	//#################################################################################
	const accordion_onChange = (panel) => (event, isExpanded) => {

		setExpanded(isExpanded ? panel : false);
	}

	//#################################################################################
	const formatAudienceCSVText = (text, orderedList = false) => {

		let result = []

		const rows = text.split('\n')
		for (const each_row of rows)
		{
			const cols = each_row.split(/\s*:\s*/i)
			result.push( cols )
		}

		return (
			<div className="table">
				{result.map((row, rIdx) => {
					return (
						<div className="tr" key={`tr_${rIdx}`}>
							{row.length > 1
								?	<>
										{orderedList 
											? <div className="td td_label">{rIdx+1}.</div>
											: null
										}
										<div className="td td_label">{row[0]}</div>
										<div className="td td_value">{row[1].replace(/%/i, ' %')}</div>
									</>
								:	<div className="td">{row[0]}</div>
							}
						</div>
					)
				})}
			</div>
		)
	}

	//#################################################################################
	const renderHandleAndReach = (platform_name, handle_nr, icon, icon_alt, handle, reach) => {
		const hasNo = ( typeof(handle_nr)!=='undefined' && handle_nr!==null && typeof(handle_nr)==='number' )
		return (reach !== null
			?	<div className="row">
					<div className="column w50"><div className="label"><span className="bold">{platform_name}</span> Handle {hasNo ? `(${handle_nr})` : null}</div> <div className="value wIcon"><img className="icon" src={icon} alt={icon_alt} />{handle !== null ? handle : '-'}</div></div>
					<div className="column w50"><div className="label"><span className="bold">{platform_name}</span> Reach {hasNo ? `(${handle_nr})` : null}</div> <div className="value">{reach != null ? reach : '-'}</div></div>
				</div>
			:	null
		)
	}

	//#################################################################################
	const renderInfluencerGroup = ( group_name ) => {

		const influencerList = influencers[group_name]

		if ( typeof(influencerList)!=='object' || influencerList===null || Array.isArray(influencerList)===false )
		{
			return null
		}

		return (
			<div 
				key={`group_${group_name}`}
				className={classnames("influencer-group", "data-scroll-cls")} 
				data-scroll
			>
				<h3 className="h3_title">{group_name}</h3>
				<div className="influencer-list">
					{influencerList.map((influencer, i) => {
						let numChannels = 0
						for (const each of DB_CHANNELS)
						{
							numChannels += ( influencer[each] !== null )
						}
						const hasFirstName = ( influencer.first_name !== null )
						const hasLastName = ( influencer.last_name !== null )
						const hasSpaceBetweenName = ( hasFirstName && hasLastName )
						// console.log(">>>>>>>>>>>", influencer)
						return (
							<Accordion 
								expanded={ expanded === `panel_${group_name}_${i}` } 
								onChange={ accordion_onChange(`panel_${group_name}_${i}`) }
								key={`influencer_${group_name}_${i}`}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									// aria-controls="panel1bh-content"
								>
									<div className="influencer-header">
										<div className="name">
											{hasFirstName ? <span className={classnames('fname', {'margin-right': hasSpaceBetweenName})}>{influencer.first_name}</span> : null}{hasLastName ? <span className="lname">{influencer.last_name}</span> : null}
										</div>
										<div className="follower"><b>{abbreviate(influencer.max_reach, 2)}</b> Follower</div>
										<div className="channels"><b>{numChannels}</b> Channel(s)</div>
										<div className="location">{influencer.location}</div>
									</div>	
								</AccordionSummary>
								<AccordionDetails className="influencer-details">
									<div className="row">
										<div className="column w25"><div className="label">Age</div> <div className="value">{influencer.age !== null ? influencer.age : '-'}</div></div>
										<div className="column w25"><div className="label">Gender</div> <div className="value">{influencer.gender != null ? influencer.gender : '-'}</div></div>
										<div className="column w25"><div className="label">Engagement</div> <div className="value">{influencer.engagement != null ? influencer.engagement : '-'}</div></div>
										<div className="column w25"><div className="label">Status</div> <div className="value">{influencer.status != null ? influencer.status : '-'}</div></div>
									</div>
									{influencer.female_audience_percentage !== null
										?	<div className="row">
												<div className="column w50"><div className="label">Female Audience</div> <div className="value">{influencer.female_audience_percent !== null ? `${influencer.female_audience_percent} %` : '-'}</div></div>
												<div className="column w50"><div className="label">Male Audience</div> <div className="value">{influencer.male_audience_percent != null ? `${influencer.male_audience_percent} %` : '-'}</div></div>
											</div>
										:	null
									}
									{influencer.content_topics !== null 
										?	<div className="row">
												<div className="column top-down w100"><div className="label">Content Topics</div> <div className="value" dangerouslySetInnerHTML={{ __html: influencer.content_topics.replaceAll('\n', '<br/>')}} /></div>
											</div>
										:	null
									}
									{influencer.description !== null 
										?	<div className="row">
												<div className="column top-down w100"><div className="label">Description</div> <div className="value" dangerouslySetInnerHTML={{ __html: influencer.description.replaceAll('\n', '<br/>')}} /></div>
											</div>
										:	null
									}
									{renderHandleAndReach('Instagram', null, IconInstagram, 'IG', influencer.instagram_handle, influencer.instagram_reach)}
									{renderHandleAndReach('Twitter', 1, IconTwitter, 'TW', influencer.twitter_1_handle, influencer.twitter_1_reach)}
									{renderHandleAndReach('Twitter', 2, IconTwitter, 'TW', influencer.twitter_2_handle, influencer.twitter_2_reach)}
									{renderHandleAndReach('YouTube', 1, IconYoutube, 'YT', influencer.youtube_1_handle, influencer.youtube_1_reach)}
									{renderHandleAndReach('YouTube', 2, IconYoutube, 'YT', influencer.youtube_2_handle, influencer.youtube_2_reach)}
									{renderHandleAndReach('Snapchat', null, IconSnapchat, 'Sc', influencer.snapchat_handle, influencer.snapchat_reach)}
									{renderHandleAndReach('Pinterest', null, IconPinterest, 'PT', influencer.pinterest_handle, influencer.pinterest_reach)}
									{renderHandleAndReach('Twitch', null, IconTwitch, 'TC', influencer.twitch_handle, influencer.twitch_reach)}
									{renderHandleAndReach('TikTok', 1, IconTiktok, 'TT', influencer.tiktok_1_handle, influencer.tiktok_1_reach)}
									{renderHandleAndReach('TikTok', 2, IconTiktok, 'TT', influencer.tiktok_2_handle, influencer.tiktok_2_reach)}
									{renderHandleAndReach('Facebook', 1, IconFacebook, 'FB', influencer.facebook_1_handle, influencer.facebook_1_reach)}
									{renderHandleAndReach('Facebook', 2, IconFacebook, 'FB', influencer.facebook_2_handle, influencer.facebook_2_reach)}
									{renderHandleAndReach('LinkedIn', null, IconLinkedin, 'LI', influencer.linkedin_handle, influencer.linkedin_reach)}
									{renderHandleAndReach('SoundCloud', null, IconSoundcloud, 'SC', influencer.soundcloud_handle, influencer.soundcloud_reach)}
									{renderHandleAndReach('Spotify', null, IconSpotify, 'Sp', influencer.spotify_handle, influencer.spotify_reach)}
									{renderHandleAndReach('Vimeo', null, IconVimeo, 'Vi', influencer.vimeo_handle, influencer.vimeo_reach)}
									{influencer.all_audience_by_groups !== null
										?	<div className="row">
												{/* <div className="column top-down w100"><div className="label">All Audience by Groups</div> <div className="value" dangerouslySetInnerHTML={{ __html: influencer.all_audience_by_groups.replaceAll('\n', '<br/>')}} /></div> */}
												<div className="column top-down w50">
													<div className="label">All Audience by Groups</div>
													<div className="value">{formatAudienceCSVText(influencer.all_audience_by_groups, false)}</div>
												</div>
												{influencer.audience_ethnicity !== null
													?	<div className="column top-down w50">
															<div className="label">Audience Ethnicity</div>
															<div className="value">{formatAudienceCSVText(influencer.audience_ethnicity, false)}</div>
														</div>
													:	null
												}
											</div>
										:	null
									}
									{influencer.female_audience_by_groups!==null || influencer.male_audience_by_groups!==null
										?	<div className="row">
												{/* <div className="column top-down w50"><div className="label">Female Audience by Groups</div> <div className="value" dangerouslySetInnerHTML={{ __html: influencer.female_audience_by_groups.replaceAll('\n', '<br/>')}} /></div>
												<div className="column top-down w50"><div className="label">Male Audience by Groups</div> <div className="value" dangerouslySetInnerHTML={{ __html: influencer.male_audience_by_groups.replaceAll('\n', '<br/>')}} /></div> */}
												<div className="column top-down w50">
													<div className="label">Female Audience by Groups</div>
													<div className="value">{formatAudienceCSVText(influencer.female_audience_by_groups, false)}</div>
												</div>
												<div className="column top-down w50">
													<div className="label">Male Audience by Groups</div>
													<div className="value">{formatAudienceCSVText(influencer.male_audience_by_groups, false)}</div>
												</div>
											</div>
										:	null
									}
									{influencer.audience_top_countries!==null || influencer.audience_top_cities!==null
										?	<div className="row">
												{/* <div className="column top-down w50"><div className="label">Audience Top Countries</div> <div className="value" dangerouslySetInnerHTML={{ __html: influencer.audience_top_countries.replaceAll('\n', '<br/>')}} /></div>
												<div className="column top-down w50"><div className="label">Audience Top Cities</div> <div className="value" dangerouslySetInnerHTML={{ __html: influencer.audience_top_cities.replaceAll('\n', '<br/>')}} /></div> */}
												<div className="column top-down w50">
													<div className="label">Audience Top Countries</div>
													<div className="value">{formatAudienceCSVText(influencer.audience_top_countries, true)}</div>
												</div>
												<div className="column top-down w50">
													<div className="label">Audience Top Cities</div>
													<div className="value">{formatAudienceCSVText(influencer.audience_top_cities, true)}</div>
												</div>
											</div>
										:	null
									}
								</AccordionDetails>
							</Accordion>
						)
					})}
				</div>
			</div>
		)
	}

	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	const isOnlyExpert = user_isOnlyExpert(user)
	// console.log(">>>>>>>>>>>> isOnlyExpert", isOnlyExpert)

	if ( !isOnlyExpert )
	{
		return null
	}

	const super_tags = Object.keys( influencers ).sort()		// sorted by super tag
	// const super_tags = Object.keys( influencers )			// unsorted
	
	return (
		<div className="IncaInfluencers wrapper">
			<h2 className='h2_title data-scroll-cls' data-scroll>Inca Influencer</h2>
			{
				super_tags.map(influencer_key => {
					return renderInfluencerGroup( influencer_key )
				})
			}
		</div>
	)
}