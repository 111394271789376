import { useEffect /*, useState, useRef*/ } from "react"
import PropTypes from 'prop-types'
import classnames from "classnames"

import './NextEventDayHeader.scss'

import CardMedia from '@mui/material/CardMedia'

// tools
import dateFormat from "../../../helpers/dateFormat"
import StrapiImage from "../../../helpers/StrapiImage"

export default function NextEventDayHeader({date, showUpcomingEventsHeader}) 
{
	const format_date = dateFormat()

	//#################################################################################
	/*)
	useEffect(
		() => {
		}, 
		[]
	)
	*/
	
	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return <>
		<div className={classnames("day-outer-container", "upcoming-events")}>
			{ showUpcomingEventsHeader === true
				?	<div className="upcoming-events-inner-container">
						<div className="upcoming-ev-day-header">Upcoming Events</div>
					</div>
				: 	null
			}
			<div className="upcoming-events-day-inner-container">
				<div className="current-date">{format_date.calendar_weekdayLong_monthInWords_dateOnly_noTime(date)}</div>
			</div>
		</div>
	</>
}

NextEventDayHeader.propTypes = {
	data: PropTypes.object,
	fadeInDelay: PropTypes.number,
	cb_onClick: PropTypes.func,
	// fetchInProgress: PropTypes.bool,
	// cb_onShowMore: PropTypes.func,
}
  
NextEventDayHeader.defaultProps = {
	data: {},
	fadeInDelay: 0,
	cb_onClick: null,
	// fetchInProgress: false,
	// cb_onShowMore: null,
}