
import React from "react"
// import { useEffect } from 'react'
// import classnames from "classnames"

import "./VideoPlayer.scss"

import ReactPlayer from 'react-player/lazy'

export default function VideoPlayer({ article }) 
{
	//#################################################################################
	// useEffect(
	// 	() => {},
	// 	[]
	// )

	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return (
		<div className="VideoPlayer" data-scroll>
			<div className='player-wrapper'>
				<ReactPlayer
					className='react-player'
					url={article.video_url}
					width='100%'
					height='100%'
					controls={true}
					playsinline={true}
					config={{
						youtube: {
							playerVars: { 
								showinfo: 0,
								modestbranding: 1
							}
						}
					}}
				/>
			</div>
		</div>
	)
}
