
import React from "react"
// import { useEffect } from 'react'
// import classnames from "classnames"

import "./PaddingTop.scss"

export default function PaddingTop({ article }) 
{
	//#################################################################################
	// useEffect(
	// 	() => {},
	// 	[]
	// )

	//---------------------------------------------------------------------------------
	//=================================================================================
	//#################################################################################
	//=================================================================================
	//---------------------------------------------------------------------------------
	return (<>
		<div className="PaddingTop" />
	</>)
}
