import { createSlice } from '@reduxjs/toolkit'
import localData from '../../helpers/localData'

export const userSlice = createSlice({
	name: 'user',
	
	initialState: {
		value: localData().getUser(),
	},

	reducers: {
		//#################################################################################
		logIn: (state, action) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes

			// console.log('-- logIn :: action', action)
			localData().setUser( action.payload )
			state.value = action.payload
		},

		//#################################################################################
		logOut: (state) => {
			localData().removeUser()
			state.value = null
		},
	},
})

// Action creators are generated for each case reducer function
export const { logIn, logOut } = userSlice.actions

export default userSlice.reducer