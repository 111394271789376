
//#################################################################################
class FileDownload
{
	static API_URL = process.env.REACT_APP_API_URL

	// constructor() {}

	//=============================================================================
	static internal_href(download_link)
	{
		// console.log("++ FileDownload :: download_link", download_link)
		const url = download_link.url || ''
		return ( url.length > 0
			? this.API_URL + url
			: '#'
		)
	}
}

export default FileDownload